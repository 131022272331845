import React, { useState, useEffect, useRef } from 'react'; 
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import Navbar from '../../../../components/Navbar4';
import Badge from '../../../../components/Badge';
import Footer from '../../../../components/Footer';
import Navbarloading from '../../../../components/Navbarloading';
import { Helmet } from 'react-helmet';
import Loading from '../../../../components/Loading';

const City = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { city } = useParams();

    // ดึง URL parameters ทันทีที่ component ถูกสร้างขึ้น
    const params = new URLSearchParams(location.search);

    // ตั้งค่าสถานะตัวกรองเริ่มต้นจาก URL parameters
    const [searchTerm, setSearchTerm] = useState(params.get('search') || '');
    const [selectedCategory, setSelectedCategory] = useState(params.get('category') || '');
    const [priceRange, setPriceRange] = useState(params.get('priceRange') || '');
    const [selectedMonth, setSelectedMonth] = useState(params.get('month') ? params.get('month').split(',') : []);
    const [selectedFestivals, setSelectedFestivals] = useState(params.get('festivals') ? params.get('festivals').split(',') : []);
    const [selectedAirlines, setSelectedAirlines] = useState(params.get('airlines') ? params.get('airlines').split(',') : []);
    const [priceSortOrder, setPriceSortOrder] = useState(params.get('sort') || 'lowest');
    const [currentPage, setCurrentPage] = useState(params.get('page') ? parseInt(params.get('page')) : 1);

    const programsPerPage = 16;
    const [programs, setPrograms] = useState([]);
    const [filteredPrograms, setFilteredPrograms] = useState([]);
    const [categories, setCategories] = useState([]);
    const [anotherPrograms, setAnotherPrograms] = useState([]);
    const [japanImages, setJapanImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [navbarVisible, setNavbarVisible] = useState(true);
    const [filterCount, setFilterCount] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [airlineIcons, setAirlineIcons] = useState({});
    const [airlineSearch, setAirlineSearch] = useState('');
    const [isFiltering, setIsFiltering] = useState(false);
    const [isLoadingFilteredPrograms, setIsLoadingFilteredPrograms] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [searchMonth, setSearchMonth] = useState('');
    const [searchCategories, setSearchCategories] = useState('');

    const lastScroll = useRef(0);
    const prevcity = useRef(city);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(false); 
    const [showMore, setShowMore] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);




    const toggleOffcanvas = () => {
      setIsOffcanvasOpen(!isOffcanvasOpen);
    };
    
    const closeOffcanvas = () => {
      setIsOffcanvasOpen(false);
    };

    useEffect(() => {
        // ตรวจสอบขนาดหน้าจอเพื่อกำหนดว่าเป็นอุปกรณ์มือถือหรือไม่
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        
        handleResize(); // ตรวจสอบครั้งแรก
        window.addEventListener('resize', handleResize); // อัปเดตเมื่อขนาดหน้าจอเปลี่ยน

        // ลบ event listener เมื่อ component ถูก unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    // ดึงเทศกาลและสายการบินที่มีอยู่
    const festivals = [...new Set(programs.flatMap(program =>
        program.festival ? program.festival.split(',').map(f => f.trim()) : []
    ))];
    const airlines = [...new Set(programs.map(program => program.airline_code))];

    const categoryFestivalCounts = {
        // คุณสามารถเพิ่มจำนวนเทศกาลที่มีได้ที่นี่
    };


    const handlePageChange = async (pageNumber) => {
      setCurrentPage(pageNumber);
      setIsLoadingFilteredPrograms(true);  // Show Skeleton Loader
      scrollToTop();
      await fetchPrograms();
      setIsLoadingFilteredPrograms(false); // Hide Skeleton Loader after loading data
  };

    const clearFilters = () => {
        setSearchTerm('');
        setSelectedCategory('');
        setPriceRange('');
        setSelectedMonth([]);
        setSelectedFestivals([]);
        setSelectedAirlines([]);
        setPriceSortOrder('lowest');
        setCurrentPage(1);
        setFilterCount(0);
    };

    const fetchPrograms = async (filters = {}) => {
      setIsLoading(true);
      try {
         // Show Skeleton Loader
    
        const tourName = city;
    
        if (!tourName) throw new Error("Invalid tour name or missing tour name.");
    
        // Build the query parameters based on filters
        const params = new URLSearchParams();
    
        if (filters.searchTerm) params.append('search', filters.searchTerm);
        if (filters.selectedCategory) params.append('category', filters.selectedCategory);
        if (filters.priceRange) params.append('priceRange', filters.priceRange);
        if (filters.selectedMonth && filters.selectedMonth.length > 0)
          params.append('month', filters.selectedMonth.join(','));
        if (filters.selectedFestivals && filters.selectedFestivals.length > 0)
          params.append('festivals', filters.selectedFestivals.join(','));
        if (filters.selectedAirlines && filters.selectedAirlines.length > 0)
          params.append('airlines', filters.selectedAirlines.join(','));
        if (filters.priceSortOrder) params.append('sort', filters.priceSortOrder);
        if (filters.currentPage && filters.currentPage > 1) params.append('page', filters.currentPage);
    
        // Build the URL with query parameters
        const programUrl = `http://localhost:5000/api/thaifly/program/${tourName}?${params.toString()}`;
        const categoryUrl = `http://localhost:5000/api/thaifly/${tourName}`;
        const imageUrl = `http://localhost:5000/data/image/${tourName}`;
    
        const responses = await Promise.all([
          fetch(programUrl),
          fetch(categoryUrl),
          fetch(imageUrl),
        ]);
    
        const errors = responses.filter((response) => !response.ok);
        if (errors.length > 0) throw new Error('Failed to fetch data from one or more APIs');
    
        const [programsResponse, categoriesResponse, imagesResponse] = await Promise.all(
          responses.map((response) => response.json())
        );
    
        setPrograms(programsResponse.product || []);
        setFilteredPrograms(programsResponse.product || []);
        setCategories([...new Set(programsResponse.product.map((program) => program.category))]);
        setAnotherPrograms(categoriesResponse.category || []);
        setJapanImages(imagesResponse.images || []);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch data.');
        setLoading(false);
      } finally {
        setIsLoading(false); // Hide Skeleton Loader after loading data
      }
    };
    

    // ดึงข้อมูลโปรแกรมเมื่อ component ถูก mount หรือเมื่อ 'city' เปลี่ยน
    useEffect(() => {
        fetchPrograms();
    }, [city]);

            // Trigger filters on dependency change
            useEffect(() => {
              setIsLoadingFilteredPrograms(true); // Show loading skeleton
              filterPrograms();
              setTimeout(() => setIsLoadingFilteredPrograms(false), 1000); // Simulating a delay for the filtering
          }, [searchTerm, selectedCategory, priceRange, selectedMonth, selectedFestivals, selectedAirlines, priceSortOrder]);

    // อัปเดตสถานะตัวกรองเมื่อ URL parameters เปลี่ยน
    useEffect(() => {
        const params = new URLSearchParams(location.search);

        setSearchTerm(params.get('search') || '');
        setSelectedCategory(params.get('category') || '');
        setPriceRange(params.get('priceRange') || '');
        setSelectedMonth(params.get('month') ? params.get('month').split(',') : []);
        setSelectedFestivals(params.get('festivals') ? params.get('festivals').split(',') : []);
        setSelectedAirlines(params.get('airlines') ? params.get('airlines').split(',') : []);
        setPriceSortOrder(params.get('sort') || 'lowest');
        setCurrentPage(params.get('page') ? parseInt(params.get('page')) : 1);
    }, [location.search]);

    // อัปเดต URL parameters เมื่อสถานะตัวกรองหรือหน้าปัจจุบันเปลี่ยน
    useEffect(() => {
        const params = new URLSearchParams();

        if (searchTerm) params.append('search', searchTerm);
        if (selectedCategory) params.append('category', selectedCategory);
        if (priceRange) params.append('priceRange', priceRange);
        if (selectedMonth.length > 0) params.append('month', selectedMonth.join(','));
        if (selectedFestivals.length > 0) params.append('festivals', selectedFestivals.join(','));
        if (selectedAirlines.length > 0) params.append('airlines', selectedAirlines.join(','));
        if (priceSortOrder) params.append('sort', priceSortOrder);
        if (currentPage && currentPage > 1) params.append('page', currentPage);

        const queryString = params.toString();
        const currentQueryString = location.search.startsWith('?') ? location.search.substring(1) : location.search;

        if (queryString !== currentQueryString) {
            navigate(`?${queryString}`, { replace: true });
        }
    }, [
        searchTerm,
        selectedCategory,
        priceRange,
        selectedMonth,
        selectedFestivals,
        selectedAirlines,
        priceSortOrder,

        navigate,
    ]);

    // กรองโปรแกรมเมื่อโปรแกรมหรือสถานะตัวกรองเปลี่ยน
    useEffect(() => {
        if (!loading) {
            filterPrograms();
        }
    }, [
        programs,
        searchTerm,
        selectedCategory,
        priceRange,
        selectedMonth,
        selectedFestivals,
        selectedAirlines,
        priceSortOrder,
        loading
    ]);

    const filterPrograms = () => {
        let filtered = [...programs];

        if (searchTerm) {
          filtered = filtered.filter((program) =>
              program.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              program.model.toLowerCase().includes(searchTerm.toLowerCase())
          );
      }
        if (selectedCategory) {
            filtered = filtered.filter((program) => program.category === selectedCategory);
        }
        if (priceRange) {
            const [min, max] = priceRange.split('-').map(Number);
            filtered = filtered.filter((program) =>
                program.price >= (min || 0) && (max === 0 || program.price <= max)
            );
        }
        if (selectedMonth.length > 0) {
            filtered = filtered.filter((program) =>
                selectedMonth.some((month) => program.period_month.includes(month))
            );
        }
        if (selectedFestivals.length > 0) {
            filtered = filtered.filter((program) =>
                selectedFestivals.some((festival) => program.festival?.includes(festival))
            );
        }
        if (selectedAirlines.length > 0) {
            filtered = filtered.filter((program) =>
                selectedAirlines.includes(program.airline_code)
            );
        }
        if (priceSortOrder === 'lowest') {
            filtered.sort((a, b) => a.price - b.price);
        } else if (priceSortOrder === 'highest') {
            filtered.sort((a, b) => b.price - a.price);
        }

        setFilteredPrograms(filtered);
        scrollToTop();
    };

    // รีเซ็ตหน้าปัจจุบันเมื่อสถานะตัวกรองเปลี่ยน
    useEffect(() => {
        setCurrentPage(1);
    }, [
        searchTerm,
        selectedCategory,
        priceRange,
        selectedMonth,
        selectedFestivals,
        selectedAirlines,
        priceSortOrder,
    ]);

    // ฟังก์ชันจัดการการเปลี่ยนแปลงของ checkbox
    const handleFestivalCheckboxChange = (festival) => {
        if (selectedFestivals.includes(festival)) {
            setSelectedFestivals(selectedFestivals.filter((f) => f !== festival));
        } else {
            setSelectedFestivals([...selectedFestivals, festival]);
        }
    };

    const handleAirlineCheckboxChange = (airline) => {
        if (selectedAirlines.includes(airline)) {
            setSelectedAirlines(selectedAirlines.filter((a) => a !== airline));
        } else {
            setSelectedAirlines([...selectedAirlines, airline]);
        }
    };

    const handleMonthChange = (input) => {
        const value = typeof input === 'string' ? input : input?.target?.value;

        if (!value) {
            console.error("Month value is undefined");
            return;
        }

        setSelectedMonth((prevSelected) => {
            const updatedSelected = prevSelected.includes(value)
                ? prevSelected.filter((month) => month !== value)
                : [...prevSelected, value];
            return updatedSelected.length === 0 ? [] : updatedSelected;
        });
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // การจัดการการเลื่อนหน้าจอสำหรับ navbar และปุ่มเลื่อนขึ้นด้านบน
    useEffect(() => {
        const handleScroll = () => {
            const currentScroll = window.pageYOffset;
            if (currentScroll <= 0) {
                setNavbarVisible(true);
                setShowScrollToTop(false);
                return;
            }

            if (currentScroll > lastScroll.current) {
                setNavbarVisible(false);
            } else {
                setNavbarVisible(true);
            }

            setShowScrollToTop(currentScroll > 300);

            lastScroll.current = currentScroll;
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // ฟังก์ชันจัดรูปแบบราคา
    const formatPrice = (price) => {
        if (!price) return '0';
        return new Intl.NumberFormat('th-TH', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(price);
    };

    // ฟังก์ชันจัดรูปแบบช่วงราคา
    const formatPriceRange = (priceRange) => {
        return priceRange
          .split('-')
          .map((price) => `฿${Number(price).toLocaleString()}`)
          .join(' - ');
    };

    // กรองสายการบินตามการค้นหา
    const filteredAirlines = [...new Set(programs.map(program => program.airline_code))]
        .filter((airline) => airline.toLowerCase().includes(airlineSearch.toLowerCase()))
        .sort((a, b) => a.localeCompare(b));

    // จัดการการเปลี่ยนแปลงการค้นหาสายการบิน
    const handleAirlineSearchChange = (e) => {
        setAirlineSearch(e.target.value);
    };

    // โหลดไอคอนสายการบิน
    useEffect(() => {
        const icons = {};
        programs.forEach(program => {
            if (program.airline_code && program.airline_icon) {
                icons[program.airline_code] = program.airline_icon;
            }
        });
        setAirlineIcons(icons);
    }, [programs]);

    // การคำนวณสำหรับการแบ่งหน้า
    const indexOfLastProgram = currentPage * programsPerPage;
    const indexOfFirstProgram = indexOfLastProgram - programsPerPage;
    const currentPrograms = filteredPrograms.slice(indexOfFirstProgram, indexOfLastProgram);
    const totalPages = Math.ceil(filteredPrograms.length / programsPerPage);

    useEffect(() => {
        const searchCount = searchTerm ? 1 : 0;
        const categoryCount = selectedCategory ? 1 : 0;
        const priceRangeCount = priceRange ? 1 : 0;
        const monthCount = selectedMonth.length;
        const festivalCount = selectedFestivals.length;
        const airlineCount = selectedAirlines.length;
        const sortOrderCount = (priceSortOrder && priceSortOrder !== 'lowest') ? 1 : 0;

        const totalFilterCount = searchCount + categoryCount + priceRangeCount + monthCount + festivalCount + airlineCount + sortOrderCount;

        setFilterCount(totalFilterCount);
    }, [
        searchTerm,
        selectedCategory,
        priceRange,
        selectedMonth,
        selectedFestivals,
        selectedAirlines,
        priceSortOrder
    ]);

    // ฟังก์ชันเลื่อนขึ้นด้านบนอย่างนุ่มนวล
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const renderPagination = () => {
      const pageNumbers = [];
      pageNumbers.push(1);
  
      if (currentPage > 3) {
          pageNumbers.push('start-ellipsis');
      }
  
      let startPage = Math.max(2, currentPage - 1);
      let endPage = Math.min(totalPages - 1, currentPage + 1);
  
      if (startPage <= endPage) {
          for (let i = startPage; i <= endPage; i++) {
              pageNumbers.push(i);
          }
      }
  
      if (totalPages > 1 && !pageNumbers.includes(totalPages)) {
          if (!pageNumbers.includes(totalPages - 1)) {
              pageNumbers.push('end-ellipsis');
          }
          pageNumbers.push(totalPages);
      }
  
      return (
          <div className="flex justify-center mt-4 space-x-1 sm:space-x-2">
              {/* Mobile View - Show only Prev, CurrentPage/TotalPages, and Next */}
              <div className="flex sm:hidden items-center space-x-2">
                  <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={`px-2 py-1 rounded text-xs ${currentPage === 1 ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-gray-200 hover:bg-gray-300'}`}
                  >
                      ย้อนกลับ
                  </button>
                  <span className="text-xs">{currentPage} / {totalPages}</span>
                  <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className={`px-2 py-1 rounded text-xs ${currentPage === totalPages ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-gray-200 hover:bg-gray-300'}`}
                  >
                      ถัดไป
                  </button>
              </div>
  
              {/* Desktop View - Full Pagination */}
              <div className="hidden sm:flex justify-center space-x-1 sm:space-x-2">
                  <button
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                      className={`px-2 py-1 sm:px-3 sm:py-1 rounded text-xs sm:text-sm ${currentPage === 1 ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-gray-200 hover:bg-gray-300'}`}
                  >
                      First
                  </button>
                  <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={`px-2 py-1 sm:px-3 sm:py-1 rounded text-xs sm:text-sm ${currentPage === 1 ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-gray-200 hover:bg-gray-300'}`}
                  >
                      Prev
                  </button>
                  {pageNumbers.map((number, index) =>
                      number === 'start-ellipsis' || number === 'end-ellipsis' ? (
                          <span key={`ellipsis-${number}-${index}`} className="px-1 sm:px-2 py-1 text-xs sm:text-sm">...</span>
                      ) : (
                          <button
                              key={`page-${number}`}
                              onClick={() => handlePageChange(number)}
                              className={`px-2 py-1 sm:px-3 sm:py-1 rounded text-xs sm:text-sm ${currentPage === number ? 'bg-blue-600 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                          >
                              {number}
                          </button>
                      )
                  )}
                  <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className={`px-2 py-1 sm:px-3 sm:py-1 rounded text-xs sm:text-sm ${currentPage === totalPages ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-gray-200 hover:bg-gray-300'}`}
                  >
                      Next
                  </button>
                  <button
                      onClick={() => handlePageChange(totalPages)}
                      disabled={currentPage === totalPages}
                      className={`px-2 py-1 sm:px-3 sm:py-1 rounded text-xs sm:text-sm ${currentPage === totalPages ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-gray-200 hover:bg-gray-300'}`}
                  >
                      Last
                  </button>
              </div>
          </div>
      );
  };
  

    // การจัดการการแสดงผลตามสถานะการโหลด ข้อผิดพลาด หรือข้อมูล
    if (loading) {
        return (
            <div>
                <Loading />
            </div>
        );
    }

    return (
        <>

{anotherPrograms.map(category => (
                <figure key={category.category_id}>
<Helmet>
      <title>{category.meta_title}</title>
      <meta name="description" content={category.meta_description} />
      <meta name="keywords" content={category.meta_keyword} />

    </Helmet>
                </figure>
            ))}
            <div className="bg-gray-100 min-h-screen">

                    <Navbar />


                <div className={`w-full bg-white border-t border-b border-gray-300 sticky z-40 transition-all duration-300 ${navbarVisible ? 'top-[64px] md:top-[74px]' : 'top-0'}`}>
                    <div className="container mx-auto flex justify-between items-center py-2 px-4">
                        <div className="flex items-center w-full">



                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-500 mr-3"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M21 21l-4.35-4.35M17 10a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                            </svg>
                            <input
                                type="text"
                                className="flex-grow bg-gray-100 p-2 rounded-lg focus:outline-none text-sm sm:text-base"
                                placeholder="ค้นหาประเทศ,เมือง,สถานที่,รหัสทัวร์"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <button
    className="py-1.5 px-3 sm:py-2 sm:px-5 text-xs sm:text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border 
    border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 ml-2 sm:ml-4 
    flex items-center md:hidden" // `md:hidden` hides it on medium screens and up
    aria-expanded={isOffcanvasOpen}
    onClick={toggleOffcanvas}
>
    {filterCount > 0 && (
        <div className="flex items-center justify-center bg-blue-500 text-white rounded-full w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2">
            {filterCount}
        </div>
    )}

    {filterCount === 0 && (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-sort-up mr-1" viewBox="0 0 16 16">
            <path d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707zm3.5-9a.5.5 0 0 1 .5-.5h7 a.5.5 0 0 1 0 1h-7 a.5.5 0 0 1-.5-.5M7.5 6 a.5.5 0 0 0 0 1h5 a.5.5 0 0 0 0-1zm0 3 a.5.5 0 0 0 0 1h3 a.5.5 0 0 0 0-1zm0 3 a.5.5 0 0 0 0 1h1 a.5.5 0 0 0 0-1z"/>
        </svg>
    )}
    <span>Filters</span>
</button>

                    </div>
                </div>



                <div className="container mx-auto px-2 flex flex-wrap mt-10">

                {anotherPrograms.map((category) =>
  category.thumb ? (
    <div key={category.category_id} className="mt-12 relative w-full h-[100px] sm:h-[300px] rounded-3xl overflow-hidden shadow-lg">
      
      {/* Image Carousel */}
      {japanImages.length > 0 ? (
        japanImages.map((image, index) => (
          <div
            key={index}
            className={`carousel-item ${index === currentIndex ? 'block' : 'hidden'} absolute inset-0`}
          >
            <img
              src={image.link_image}
              alt=""
              loading="lazy"
              className="w-full h-full object-cover rounded-3xl"
            />
          </div>
        ))
      ) : null}

      {/* Text overlay */}
      <div className="absolute inset-0 flex items-center justify-center p-2 sm:p-4 rounded-3xl bg-black bg-opacity-40">
      <div className="relative text-left text-white w-full h-full">
          {/* Text content */}
          <h1 className="text-xs sm:text-sm md:text-lg lg:text-3xl flex items-center">
            <img
              src={category.icon_tour}
              alt="Icon"
              className="w-[30px] h-[30px] sm:w-[50px] sm:h-[50px] mr-2 mb-1 sm:mb-2"
            />
            <span className="text-white text-xs sm:text-base md:text-lg lg:text-4xl">
              {category.name}
            </span>
          </h1>
          <p className="text-[10px] sm:text-xs md:text-sm">
            {category.meta_title}
          </p>
        </div>
      </div>
    </div>
  ) : null
)}




                    <aside className="w-full md:w-1/3 lg:w-1/4 p-4 bg-white rounded-2xl border mt-10 mb-4">
                     
      <div className="flex items-center justify-between space-x-2"> 
        <span className="text-gray-600 text-lg font-semibold mb-4">
          (ค้นพบ : {filteredPrograms.length} รายการ)
        </span>

        <div className="flex items-center">
        <button
  onClick={clearFilters}
  data-tip="Clear Filter" 
  className="tooltip-bottom tooltip mr-2 focus:outline-none h-[40px] w-[40px] flex items-center justify-center text-white bg-red-700 hover:bg-red-800 focus:ring-2 focus:ring-red-300 font-medium rounded-lg transition duration-150 ease-in-out dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 px-2"
>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="20" 
    height="20" 
    fill="currentColor" 
    className="bi bi-funnel-fill" 
    viewBox="0 0 16 16"
  >
    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z"/>
  </svg>
</button>


        </div>
      </div>

                        <div className="inline-flex flex-wrap gap-2 mb-2" >

                  

          
                        <div className="flex flex-wrap gap-2 mb-2">
  {priceRange && (
    <Badge key="priceRange" label={`ช่วงราคา : ${formatPriceRange(priceRange)}`} onClear={() => setPriceRange('')} />
  )}
  {selectedMonth.map((month) => (
    <Badge key={month} label={`เดือน : ${month}`} onClear={() => handleMonthChange(month)} />
  ))}
  {selectedFestivals.map((festival) => (
    <Badge key={festival} label={`เทศกาล : ${festival}`} onClear={() => handleFestivalCheckboxChange(festival)} />
  ))}
  {selectedAirlines.map((airline) => (
    <Badge
      key={airline}
      icon={airlineIcons[airline]}
      onClear={() => handleAirlineCheckboxChange(airline)}
    />
  ))}
</div>




    
    


</div>


                        <div className="mb-4 flex flex-col space-y-4">
                            <div className="flex items-center">
                                <input
                                    id="price-highest"
                                    type="radio"
                                    value="highest"
                                    name="price-radio-group"
                                    className="ml-2 appearance-none w-5 h-5 border-2 border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200"
                                    checked={priceSortOrder === 'highest'}
                                    onChange={() => setPriceSortOrder('highest')}
                                />
                                <label
                                    htmlFor="price-highest"
                                    className="ml-3 text-sm font-medium text-gray-900 cursor-pointer hover:text-blue-600 transition duration-150 ease-in-out"
                                >
                                    ราคามากไปน้อย
                                </label>
                            </div>

                            <div className="flex items-center">
                                <input
                                    id="price-lowest"
                                    type="radio"
                                    value="lowest"
                                    name="price-radio-group"
                                    className="ml-2 appearance-none w-5 h-5 border-2 border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200"
                                    checked={priceSortOrder === 'lowest'}
                                    onChange={() => setPriceSortOrder('lowest')}
                                />
                                <label
                                    htmlFor="price-lowest"
                                    className="ml-3 text-sm font-medium text-gray-900 cursor-pointer hover:text-blue-600 transition duration-150 ease-in-out"
                                >
                                    ราคาน้อยไปมาก
                                </label>
                            </div>
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-medium text-black mb-2">ช่วงราคา</label>
                            <div className="relative">
  <select
    className="block w-full p-3 ps-4 pr-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400 transition duration-200 ease-in-out appearance-none"
    value={priceRange}
    onChange={(e) => setPriceRange(e.target.value)}
  >
    <option value="">ทุกช่วงราคา</option>
    <option value="0-10000">฿0 - ฿10,000</option>
    <option value="10000-20000">฿10,000 - ฿20,000</option>
    <option value="20000-30000">฿20,000 - ฿30,000</option>
    <option value="30000-40000">฿30,000 - ฿40,000</option>
    <option value="40000-50000">฿40,000 - ฿50,000</option>
    <option value="50000-60000">฿50,000 - ฿60,000</option>
    <option value="60000-70000">฿60,000 - ฿70,000</option>
    <option value="70000-80000">฿70,000 - ฿80,000</option>
    <option value="80000-90000">฿80,000 - ฿90,000</option>
    <option value="90000-200000">฿90,000 - ฿200,000</option>
  </select>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 pointer-events-none"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
  </svg>
</div>


                        </div>

                        <div className="collapse collapse-plus border mb-4" style={{ zoom: '80%' }}>
                            <input type="checkbox" name="my-accordion-1" defaultChecked />
                            <div className="collapse-title text-l font-medium text-black flex items-center space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-week" viewBox="0 0 16 16">
  <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
</svg>
                                <span>ค้นหาเดือน</span>
                            </div>

                            <div className="collapse-content">
                                <input
                                    id="search-month"
                                    name="searchMonth"
                                    type="text"
                                    placeholder="ค้นหาเดือน"
                                    value={searchMonth}
                                    onChange={(e) => setSearchMonth(e.target.value)}
                                    className="mt-2 w-full text-[12px] px-4 py-2 border border-gray-300 rounded-lg bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                                />
                                <div className="overflow-y-auto mt-2" style={{ zoom: '90%' }}>
                                    <div className="mt-1 ml-1">
                                        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4">
                                            {[
                                                { value: "ม.ค.", label: "มกราคม" },
                                                { value: "ก.พ.", label: "กุมภาพันธ์" },
                                                { value: "มี.ค.", label: "มีนาคม" },
                                                { value: "เม.ย.", label: "เมษายน" },
                                                { value: "พ.ค.", label: "พฤษภาคม" },
                                                { value: "มิ.ย.", label: "มิถุนายน" },
                                                { value: "ก.ค.", label: "กรกฎาคม" },
                                                { value: "ส.ค.", label: "สิงหาคม" },
                                                { value: "ก.ย.", label: "กันยายน" },
                                                { value: "ต.ค.", label: "ตุลาคม" },
                                                { value: "พ.ย.", label: "พฤศจิกายน" },
                                                { value: "ธ.ค.", label: "ธันวาคม" },
                                            ]
                                                .filter((month) =>
                                                    month.label.toLowerCase().includes(searchMonth.toLowerCase())
                                                )
                                                .map((month) => (
                                                    <label key={month.value} className="mb-2 flex items-center">
                                                        <input
                                                            type="checkbox"
                                                            value={month.value}
                                                            checked={selectedMonth.includes(month.value)}
                                                            onChange={handleMonthChange}
                                                            className="hidden"
                                                        />
                                                        <span
                                                            className={`cursor-pointer px-4 py-2 border rounded-md text-center w-full ${
                                                                selectedMonth.includes(month.value)
                                                                    ? 'bg-blue-500 text-white border-blue-500'
                                                                    : 'bg-white text-gray-900 border-gray-300'
                                                            } hover:bg-blue-100 transition duration-200 ease-in-out`}
                                                        >
                                                            {month.label}
                                                        </span>
                                                    </label>
                                                ))} 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="collapse collapse-plus border mb-4" style={{ zoom: '90%' }}>
                            <input type="checkbox" name="my-accordion-2" defaultChecked />
                            <div className="collapse-title text-l font-medium text-black flex items-center space-x-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-calendar-check"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                </svg>
                                <span>ค้นหาช่วงเทศกาล</span>
                            </div>

                            <div className="collapse-content">
                                <input
                                    id="search-category"
                                    name="searchCategories"
                                    type="text"
                                    placeholder="ค้นหาหมวดหมู่เทศกาล"
                                    value={searchCategories}
                                    onChange={(e) => setSearchCategories(e.target.value)}
                                    className="mt-2 w-full text-[13px] px-4 py-2 border border-gray-300 rounded-lg bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                                />
                                <div className="overflow-y-auto mt-2" style={{ maxHeight: '540px' }}>
                                {festivals.filter(festival => festival && festival.toLowerCase().includes(searchCategories.toLowerCase())).length === 0 ? (
    <div className="text-gray-500 items-center text-sm">No Festivals found.</div>
) : (
    festivals.filter(festival => festival && festival.toLowerCase().includes(searchCategories.toLowerCase())).map((festival, index) => {
        const festivalCount = categoryFestivalCounts[festival] || 0;
        return (
            <h3 key={`festival-${index}`} className="flex items-center mb-2 mt-2">
              <input
                id={`festival-checkbox-${index}`}
                type="checkbox"
                value={festival}
                checked={selectedFestivals.includes(festival)}
                onChange={() => handleFestivalCheckboxChange(festival)}
                className="ml-2 appearance-none w-5 h-5 border-2 border-gray-300 rounded-md checked:bg-green-500 checked:border-transparent focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200"
              />
              <label
                htmlFor={`festival-checkbox-${index}`}
                className="ml-2 text-sm font-medium text-gray-800 flex items-center"
              >
                <span>{festival}</span>
                {festivalCount > 0 && (
                  <span className="ml-2 text-gray-600">({festivalCount})</span>
                )}
              </label>
            </h3>
        );
      })
)}

                                </div>
                            </div>
                        </div>

                        {/* Airline Filter Section */}
                        <div className="collapse collapse-plus border mb-4" style={{ zoom: '90%' }}>
                            <input type="checkbox" name="my-accordion-3" defaultChecked />
                            <div className="collapse-title text-l font-medium text-black flex items-center space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-airplane" viewBox="0 0 16 16">
  <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849m.894.448C7.111 2.02 7 2.569 7 3v4a.5.5 0 0 1-.276.447l-5.448 2.724a.5.5 0 0 0-.276.447v.792l5.418-.903a.5.5 0 0 1 .575.41l.5 3a.5.5 0 0 1-.14.437L6.708 15h2.586l-.647-.646a.5.5 0 0 1-.14-.436l.5-3a.5.5 0 0 1 .576-.411L15 11.41v-.792a.5.5 0 0 0-.276-.447L9.276 7.447A.5.5 0 0 1 9 7V3c0-.432-.11-.979-.322-1.401C8.458 1.159 8.213 1 8 1s-.458.158-.678.599"/>
</svg>
                                <span>ค้นหาสายการบิน</span>
                            </div>

                            <div className="collapse-content">
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        placeholder="ค้นหาสายการบิน"
                                        value={airlineSearch}
                                        onChange={handleAirlineSearchChange}
                                        className="mt-2 w-full text-[12px] px-4 py-2 border border-gray-300 rounded-lg bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                                    />
                                </div>
                                <div className="overflow-y-auto" style={{ maxHeight: '650px' }}>
                                {filteredAirlines.length === 0 ? (
    <div className="text-gray-500 items-center text-sm">No Airlines found.</div>
) : (filteredAirlines.map((airline, index) => (
    <h3 key={`airline-${index}`} className="flex items-center mb-2 mt-2">
      <input
        id={`airline-checkbox-${index}`}
        type="checkbox"
        value={airline}
        checked={selectedAirlines.includes(airline)}
        onChange={() => handleAirlineCheckboxChange(airline)}
        className="ml-2 appearance-none w-5 h-5 border-2 border-gray-300 rounded-md checked:bg-green-500 checked:border-transparent focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200"
      />
      <label
        htmlFor={`airline-checkbox-${index}`}
        className="ml-2 text-sm font-medium text-gray-800 flex items-center"
      >
        {airlineIcons[airline] && (
          <img
            src={airlineIcons[airline]}
            alt={airline}
            className="w-auto h-10 rounded-lg mr-2"
          />
        )}
        <span></span>
      </label>
    </h3>
  ))
)}

                                </div>
                            </div>
                        </div>
                    </aside>

                    {/* Main Content for Programs */}


                    <div className="container mx-auto px-4 flex-1 mx-auto md:w-3/4 lg:w-4/5 p-4 mt-6">

                    {anotherPrograms.map((category, index) => (
                      
  <div key={index} className="category-card mb-4 relative">
    {/* Displaying Sub-menu in horizontal layout */}
    
    <ul 
      className={`flex flex-wrap gap-3 justify-left ${
        isMobile && !showMore ? 'max-h-[5rem] overflow-hidden' : ''
      } relative z-10`}  // Ensure sub-menu stays in the correct layer
    >
      {category.sub_menu.map((subItem, subIndex) => (
        <li key={subIndex} className="sub-menu-item mt-1">
          <a 
            href={subItem.href} 
            className="bg-blue-100 text-blue-800 text-sm md:text-sm font-medium me-2 px-2.5 py-0.5 rounded-full"
          >
            #{subItem.name}
          </a>
        </li>
      ))}
    </ul>

    {/* Gradient overlay for fade effect - positioned behind the button but in front of the sub-menu */}
    {isMobile && !showMore && category.sub_menu.length > 6 && (
      <div className="absolute bottom-10 left-0 right-0 h-20 bg-gradient-to-t from-gray-100 to-transparent pointer-events-none z-20"></div>
    )}

    {/* Show More / Show Less Button only on mobile */}
    {isMobile && category.sub_menu.length > 6 && (
      <div className="flex justify-center mt-4 z-30 relative">
        <button 
          onClick={toggleShowMore} 
          className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-[30px] border border-transparent bg-blue-600 
          text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
        >
          {showMore ? 'แสดงน้อยลง' : 'แสดงเพิ่มเติม'}
        </button>
      </div>
    )}

  </div>
))}





<div className="text-center md:hidden">
  <span className="text-gray-600 text-lg font-semibold mb-2">
    (ค้นพบ : {filteredPrograms.length} รายการ)
  </span>
</div>


  {error && (
    <div className="text-red-600 mb-4">{error}</div>
  )}

  {isLoadingFilteredPrograms ? (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {Array.from({ length: 16 }).map((_, index) => (
        <div key={`loading-${index}`} className="bg-white border border-gray-200 rounded-xl shadow-xl p-4 animate-pulse">
          <div className="flex justify-center items-center bg-gray-200 overflow-hidden rounded-xl w-full aspect-square">
            {/* Image Skeleton Placeholder */}
          </div>
          <div className="mt-4 space-y-2">
            <div className="h-4 bg-gray-300 rounded w-1/2"></div>
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
          </div>
          <div className="mt-4 space-y-2">
            <div className="flex justify-between text-xs text-gray-500">
              <div className="flex flex-col items-start">
                <div className="h-4 bg-gray-300 rounded w-12"></div>
                <div className="h-4 bg-gray-300 rounded w-16 mt-1"></div>
              </div>
              <div className="border-r-2 border-gray-200 mx-4"></div>
              <div className="flex flex-col items-end">
                <div className="h-4 bg-gray-300 rounded w-12"></div>
                <div className="h-4 bg-gray-300 rounded w-10 mt-1"></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : filteredPrograms.length === 0 ? (
    <div className="flex flex-col items-center justify-center text-center mt-4">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" 
      className="h-16 w-16 text-red-500 mb-4" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
      </svg>
      <div className="text-xl font-semibold text-gray-700">ขออภัยไม่มีรายการที่คุณค้นหา</div>
    </div>
  ) : (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
{currentPrograms.map((program, index) => (
  <div key={program.program_id || index} className="bg-white border border-gray-200 rounded-xl hover:shadow-xl transition-shadow duration-200">
          <a href={`/outbound/tour/${program.model}`} rel="noopener noreferrer">
            {/* Ensuring the image container fits dynamically within the card */}
            <div className="flex justify-center overflow-hidden rounded-t-xl">
              <img
                src={program.popup}
                alt={program.name}
                className="w-full h-full object-contain"
              />
            </div>
          </a>
          <div className="p-4">
            <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
              {program.model}
            </span>
            <h3 className="text-sm sm:text-md lg:text-lg font-semibold text-blue-500 overflow-hidden line-clamp-2 mb-2">
              {program.name}
            </h3>
            <div className="text-sm text-gray-500 overflow-hidden line-clamp-1 mb-2">
              <span>ช่วงเดินทาง: {program.period_month}</span>
            </div>
            <div className="flex justify-between text-xs sm:text-sm text-gray-700 mb-4">
              <div className="flex flex-col items-start">
                <span className="text-xs text-gray-500">จำนวนวัน</span>
                <span className="text-xs">{program.day} วัน | {program.night} คืน</span>
              </div>
              <div className="border-r-2 border-gray-200 mx-4"></div>
              <div className="flex flex-col items-end">
                <span className="text-xs text-gray-500">สายการบิน</span>
                <img
                  src={program.airline_icon}
                  alt={program.airline_code}
                  className="w-auto h-6 rounded-lg"
                />
              </div>
            </div>
            <div className="flex justify-between items-center mt-auto">
  <span className="text-xl sm:text-lg font-semibold text-red-600">
    {formatPrice(program.price)} บาท
  </span>
  <a
    href={`/outbound/tour/${program.model}`}
    className="cursor-pointer flex items-center bg-blue-600 text-white hover:bg-blue-700 transition duration-200 px-3 py-2 sm:px-2 sm:py-1 md:px-1 md:py-0.5 rounded"
  >
    <span className="mr-2 text-base sm:text-sm md:text-xs">ดูเพิ่มเติม</span>
    <svg
      className="w-4 h-4 sm:w-3 sm:h-3 md:w-2 md:h-2"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 10"
    >
      <path stroke="currentColor" d="M1 5h12m0 0L9 1m4 4L9 9" />
    </svg>
  </a>
</div>

          </div>
        </div>
      ))}
    </div>
  )}

  {renderPagination()}






  {showScrollToTop && (
    <button
      onClick={scrollToTop}
      title="ย้อนกลับขึ้นไปด้านบน"
      className="fixed bottom-8 right-5 w-12 h-12 flex items-center justify-center rounded-full bg-blue-600 text-white shadow-lg transition-transform transform hover:scale-110"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="24" height="24">
        <path d="M12 19V6"></path>
        <polyline points="5 12 12 5 19 12"></polyline>
      </svg>
    </button>
  )}
</div>


    








                </div>
            </div>



            {isOffcanvasOpen && (
<>
                {/* Blurred background overlay */}
  <div 
  className="fixed inset-0 bg-white bg-opacity-30 backdrop-blur-sm z-[70] transition-opacity duration-300" 
  onClick={closeOffcanvas}
></div>
        <div
          id="hs-offcanvas-right"
          className="fixed top-0 end-0 h-full max-w-xs w-full z-[80] bg-white border-s transition-transform transform translate-x-0 duration-300"
          role="dialog"
          aria-labelledby="hs-offcanvas-right-label"
          tabIndex="-1"
        >
          <div className="flex justify-between items-center py-3 px-4 border-b ">
            <h3 id="hs-offcanvas-right-label" className="font-bold text-gray-800 ">
            (ค้นพบ : {filteredPrograms.length} รายการ)
            </h3>
            <button
                 onClick={clearFilters}
                 data-tip="Clear Filter" 
                 className="tooltip-bottom tooltip mr-2 focus:outline-none h-[40px] w-[40px] flex items-center justify-center text-white bg-red-700 hover:bg-red-800 focus:ring-2 focus:ring-red-300 font-medium rounded-lg transition duration-150 ease-in-out dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 px-2"
               >
                 <svg 
                   xmlns="http://www.w3.org/2000/svg" 
                   width="20" 
                   height="20" 
                   fill="currentColor" 
                   className="bi bi-funnel-fill" 
                   viewBox="0 0 16 16"
                 >
                   <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z"/>
                 </svg>
             
               </button>
            <button
              type="button"
              className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
              aria-label="Close"
              onClick={closeOffcanvas}
            >
              <span className="sr-only">Close</span>
              <svg
                className="shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 6L6 18"></path>
                <path d="M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <div className="p-4">
          <div className="w-full overflow-y-auto max-h-[calc(100vh-150px)]">
                     

                                       <div className="inline-flex flex-wrap gap-2 mb-2" >
               
                                 
               



<div className="flex flex-wrap gap-2 mb-2">
  {priceRange && (
    <Badge label={`ช่วงราคา : ${formatPriceRange(priceRange)}`} onClear={() => setPriceRange('')} />
  )}
{selectedMonth.map((month) => (
    <Badge key={month} label={`เดือน : ${month}`} onClear={() => handleMonthChange(month)} />
))}
{selectedFestivals.map((festival, index) => (
  <Badge key={`festival-${festival || index}`} label={`เทศกาล : ${festival}`} onClear={() => handleFestivalCheckboxChange(festival)} />
))}
{selectedAirlines.map((airline, index) => (
  <Badge
    key={`airline-${airline || index}`} // Ensure unique key
    icon={airlineIcons[airline]}
    onClear={() => handleAirlineCheckboxChange(airline)}
  />
))}
</div>



               
               
                   
                   
               
               
               </div>
               
               
                                       <div className="mb-4 flex flex-col space-y-4">
                                           <div className="flex items-center">
                                               <input
                                                   id="price-highest"
                                                   type="radio"
                                                   value="highest"
                                                   name="price-radio-group"
                                                   className="ml-2 appearance-none w-5 h-5 border-2 border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200"
                                                   checked={priceSortOrder === 'highest'}
                                                   onChange={() => setPriceSortOrder('highest')}
                                               />
                                               <label
                                                   htmlFor="price-highest"
                                                   className="ml-3 text-sm font-medium text-gray-900 cursor-pointer hover:text-blue-600 transition duration-150 ease-in-out"
                                               >
                                                   ราคามากไปน้อย
                                               </label>
                                           </div>
               
                                           <div className="flex items-center">
                                               <input
                                                   id="price-lowest"
                                                   type="radio"
                                                   value="lowest"
                                                   name="price-radio-group"
                                                   className="ml-2 appearance-none w-5 h-5 border-2 border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200"
                                                   checked={priceSortOrder === 'lowest'}
                                                   onChange={() => setPriceSortOrder('lowest')}
                                               />
                                               <label
                                                   htmlFor="price-lowest"
                                                   className="ml-3 text-sm font-medium text-gray-900 cursor-pointer hover:text-blue-600 transition duration-150 ease-in-out"
                                               >
                                                   ราคาน้อยไปมาก
                                               </label>
                                           </div>
                                       </div>
               
                                       <div className="mb-4">
                                           <label className="block text-sm font-medium text-black mb-2">ช่วงราคา</label>
                                           <select
                                               className="w-full p-2 border border-gray-300 rounded"
                                               value={priceRange}
                                               onChange={(e) => setPriceRange(e.target.value)}
                                           >
                                               <option value="">ทุกช่วงราคา</option>
                                               <option value="0-10000">฿0 - ฿10,000</option>
                   <option value="10000-20000">฿10,000 - ฿20,000</option>
                   <option value="20000-30000">฿20,000 - ฿30,000</option>
                   <option value="30000-40000">฿30,000 - ฿40,000</option>
                   <option value="40000-50000">฿40,000 - ฿50,000</option>
                   <option value="50000-60000">฿50,000 - ฿60,000</option>
                   <option value="60000-70000">฿60,000 - ฿70,000</option>
                   <option value="70000-80000">฿70,000 - ฿80,000</option>
                   <option value="80000-90000">฿80,000 - ฿90,000</option>
                   <option value="90000-200000">฿90,000-฿200,000</option>
                                           </select>
                                       </div>
               
                                       <div className="collapse collapse-plus border mb-4" style={{ zoom: '80%' }}>
                                           <input type="checkbox" name="my-accordion-1" defaultChecked />
                                           <div className="collapse-title text-l font-medium text-black flex items-center space-x-2">
                                               <svg
                                                   xmlns="http://www.w3.org/2000/svg"
                                                   width="16"
                                                   height="16"
                                                   fill="currentColor"
                                                   className="bi bi-calendar3"
                                                   viewBox="0 0 16 16"
                                               >
                                                   <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                                                   <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                                               </svg>
                                               <span>ค้นหาเดือน</span>
                                           </div>
               
                                           <div className="collapse-content">
                                               <input
                                                   id="search-month"
                                                   name="searchMonth"
                                                   type="text"
                                                   placeholder="ค้นหาเดือน"
                                                   value={searchMonth}
                                                   onChange={(e) => setSearchMonth(e.target.value)}
                                                   className="mt-2 w-full text-[12px] px-4 py-2 border border-gray-300 rounded-lg bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                                               />
                                               <div className="overflow-y-auto mt-2" style={{ zoom: '90%' }}>
                                                   <div className="mt-1 ml-1">
                                                       <div className="grid grid-cols-3 gap-4">
                                                           {[
                                                               { value: "ม.ค.", label: "มกราคม" },
                                                               { value: "ก.พ.", label: "กุมภาพันธ์" },
                                                               { value: "มี.ค.", label: "มีนาคม" },
                                                               { value: "เม.ย.", label: "เมษายน" },
                                                               { value: "พ.ค.", label: "พฤษภาคม" },
                                                               { value: "มิ.ย.", label: "มิถุนายน" },
                                                               { value: "ก.ค.", label: "กรกฎาคม" },
                                                               { value: "ส.ค.", label: "สิงหาคม" },
                                                               { value: "ก.ย.", label: "กันยายน" },
                                                               { value: "ต.ค.", label: "ตุลาคม" },
                                                               { value: "พ.ย.", label: "พฤศจิกายน" },
                                                               { value: "ธ.ค.", label: "ธันวาคม" },
                                                           ]
                                                               .filter((month) =>
                                                                   month.label.toLowerCase().includes(searchMonth.toLowerCase())
                                                               )
                                                               .map((month) => (
                                                                   <label key={month.value} className="mb-2 flex items-center">
                                                                       <input
                                                                           type="checkbox"
                                                                           value={month.value}
                                                                           checked={selectedMonth.includes(month.value)}
                                                                           onChange={handleMonthChange}
                                                                           className="hidden"
                                                                       />
                                                                       <span
                                                                           className={`cursor-pointer px-4 py-2 border rounded-md text-center w-full ${
                                                                               selectedMonth.includes(month.value)
                                                                                   ? 'bg-blue-500 text-white border-blue-500'
                                                                                   : 'bg-white text-gray-900 border-gray-300'
                                                                           } hover:bg-blue-100 transition duration-200 ease-in-out`}
                                                                       >
                                                                           {month.label}
                                                                       </span>
                                                                   </label>
                                                               ))} 
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
               
                                       <div className="collapse collapse-plus border mb-8" style={{ zoom: '90%' }}>
                                           <input type="checkbox" name="my-accordion-2" defaultChecked />
                                           <div className="collapse-title text-l font-medium text-black flex items-center space-x-2">
                                               <svg
                                                   xmlns="http://www.w3.org/2000/svg"
                                                   width="16"
                                                   height="16"
                                                   fill="currentColor"
                                                   className="bi bi-calendar-check"
                                                   viewBox="0 0 16 16"
                                               >
                                                   <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                                                   <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                               </svg>
                                               <span>ค้นหาช่วงเทศกาล</span>
                                           </div>
               
                                           <div className="collapse-content">
                                               <input
                                                   id="search-category"
                                                   name="searchCategories"
                                                   type="text"
                                                   placeholder="ค้นหาหมวดหมู่เทศกาล"
                                                   value={searchCategories}
                                                   onChange={(e) => setSearchCategories(e.target.value)}
                                                   className="mt-2 w-full text-[13px] px-4 py-2 border border-gray-300 rounded-lg bg-white text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                                               />
                                               <div className="overflow-y-auto mt-2 h-full" >
                                               {festivals.filter(festival => festival && festival.toLowerCase().includes(searchCategories.toLowerCase())).length === 0 ? (
    <div className="text-gray-500 items-center text-sm">No Festivals found.</div>
) : (
    festivals.filter(festival => festival && festival.toLowerCase().includes(searchCategories.toLowerCase())).map((festival, index) => {
        const festivalCount = categoryFestivalCounts[festival] || 0;
        return (
            <h3 key={festival || index} className="flex items-center mb-2 mt-2">
                <input
                    id={`festival-checkbox-${index}`}
                    type="checkbox"
                    value={festival}
                    checked={selectedFestivals.includes(festival)}
                    onChange={() => handleFestivalCheckboxChange(festival)}
                    className="ml-2 appearance-none w-5 h-5 border-2 border-gray-300 rounded-md checked:bg-green-500 checked:border-transparent focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200"
                />
                <label
                    htmlFor={`festival-checkbox-${index}`}
                    className="ml-2 text-sm font-medium text-gray-800 flex items-center"
                >
                    <span>{festival}</span>
                    {festivalCount > 0 && (
                        <span className="ml-2 text-gray-600">({festivalCount})</span>
                    )}
                </label>
            </h3>
        );
    })
)}

                                               </div>
                                           </div>
                                       </div>
               
                                       {/* Airline Filter Section */}
                                       <div className="collapse collapse-plus border mb-8" style={{ zoom: '90%' }}>
                                           <input type="checkbox" name="my-accordion-3" defaultChecked />
                                           <div className="collapse-title text-l font-medium text-black flex items-center space-x-2">
                                               <span>ค้นหาสายการบิน</span>
                                           </div>
               
                                           <div className="collapse-content">
                                               <div className="mt-2">
                                                   <input
                                                       type="text"
                                                       placeholder="ค้นหาสายการบิน"
                                                       value={airlineSearch}
                                                       onChange={handleAirlineSearchChange}
                                                       className="w-full p-2 mb-4 border border-gray-300 rounded"
                                                   />
                                               </div>
                                               <div className="overflow-y-auto h-full" >
                                {filteredAirlines.length === 0 ? (
    <div className="text-gray-500 items-center text-sm">No Airlines found.</div>
) : (filteredAirlines.map((airline, index) => (
    <h3 key={`airline-${index}`} className="flex items-center mb-2 mt-2">
      <input
        id={`airline-checkbox-${index}`}
        type="checkbox"
        value={airline}
        checked={selectedAirlines.includes(airline)}
        onChange={() => handleAirlineCheckboxChange(airline)}
        className="ml-2 appearance-none w-5 h-5 border-2 border-gray-300 rounded-md checked:bg-green-500 checked:border-transparent focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200"
      />
      <label
        htmlFor={`airline-checkbox-${index}`}
        className="ml-2 text-sm font-medium text-gray-800 flex items-center"
      >
        {airlineIcons[airline] && (
          <img
            src={airlineIcons[airline]}
            alt={airline}
            className="w-auto h-10 rounded-lg mr-2"
          />
        )}
 
      </label>
    </h3>
  ))
)}

                                               </div>
                                           </div>
                                       </div>
                                   </div>

                                   <div className="flex justify-start mt-2">
  <button
    onClick={toggleOffcanvas}
    className="p-2 text-white bg-gray-400 rounded-md mr-2"
  >
    Close
  </button>


</div>


          </div>
          
        </div>

        </>
      )}
      

      
      <div className="hidden lg:block">
<Footer />
</div>

 </>
    );
};

export default City;

import React from 'react';
import { Helmet } from 'react-helmet';

function Loading() {
  return (
<>
    <Helmet>
      <title>Loading...</title>


    </Helmet>
<div className="flex flex-col items-center justify-center h-screen">
  {/*  */}
  <img
    src="/images/TF_Logo-Color.png" 
    alt="Logo"
    className="w-32 h-auto" 
  />

  <span className="loading loading-dots loading-lg text-[#009cdc] mt-1"></span>
</div>
</>
  );
}

export default Loading;

import React, { useRef, useState, useEffect } from 'react';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../../components/firebase/firebase';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Login';
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    // Simple email validation
    if (!/\S+@\S+\.\S+/.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      setLoading(false);
      return;
    }

    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
      console.log('User signed in:', user);
      Swal.fire('Login Successful', '', 'success');
      navigate(-1);  // Go back to the previous page
    } catch (error) {
      console.error("Error signing in:", error);
      setErrorMessage(error.message);
      Swal.fire('Login Failed', error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log('User signed in with Google:', result.user);
      Swal.fire(`Welcome ${result.user.displayName}`, '', 'success');
      navigate(-1);  // Go back to the previous page
    } catch (error) {
      console.error("Error signing in with Google:", error);
      setErrorMessage(error.message);
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          Swal.fire({
            title: 'Attention!',
            text: 'Please complete the login process before closing the popup.',
            icon: 'warning',
            confirmButtonText: 'Okay',
          });
          break;
        default:
          Swal.fire('Login Failed', error.message, 'error');
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Login - Thaifly</title>
        <meta name="description" content="Login to Thaifly" />
      </Helmet>
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-full max-w-xs">
          <form onSubmit={handleSubmit}>
            <div className="flex justify-center mb-6">
              <img
                src="/images/TF_Logo-Color.png"
                alt="Logo"
                className="h-16 w-auto"
              />
            </div>
            <label className="input input-bordered flex items-center gap-2 mb-3">
              <input
                type="email"
                placeholder="Email"
                ref={emailRef}
                required
                className="grow"
              />
            </label>
            <label className="input input-bordered flex items-center gap-2 mb-3">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                ref={passwordRef}
                required
                className="grow"
              />
              <button type="button" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </label>
            <button className="btn w-full mb-2" type="submit" disabled={isLoading}>
              {isLoading ? <span className="loading loading-spinner"></span> : 'Login'}
            </button>
          </form>
          <Link to="/register">
            <button className="btn btn-primary w-full text-white mb-2">
              Register
            </button>
          </Link>
          <div className="divider">OR</div>
          <button 
            className="btn w-full flex items-center justify-center gap-2 mb-2"
            onClick={signInWithGoogle}
          >
            <img
              src="https://th.bing.com/th/id/R.0fa3fe04edf6c0202970f2088edea9e7?rik=joOK76LOMJlBPw&riu=http%3a%2f%2fpluspng.com%2fimg-png%2fgoogle-logo-png-open-2000.png&ehk=0PJJlqaIxYmJ9eOIp9mYVPA4KwkGo5Zob552JPltDMw%3d&risl=&pid=ImgRaw&r=0"
              alt="Google logo"
              className="h-5 w-5"
            />
            Sign in / Sign up With Google
          </button>
          {errorMessage && (
            <div className="text-red-500 mt-2 text-center">{errorMessage}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Login;

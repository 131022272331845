import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({ label, icon, onClear }) => (
<>
<footer className="footer bg-base-200 text-base-content p-10">
  <aside>
  <img
    src="/images/TF_Logo-Color.png" 
    alt="Logo"
    className="w-32 h-auto" 
  />
    <p>
    © บริษัท ไทยฟลาย ทราเวล จำกัด สงวนลิขสิทธิ์ 
      <br />
      T.A.T. Licence No. 11/10471
    </p>
  </aside>
  <nav>
  <h6 className="footer-title">ทัวร์ทวีปเอเชีย</h6>

  <div className="grid grid-cols-2 gap-4">
    <Link to="/outbound/japan" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/japan.png" alt="Icon" className="w-4 h-4 mr-2" />
      ทัวร์ญี่ปุ่น
    </Link>

    <Link to="/outbound/china" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/china.png" alt="Icon" className="w-4 h-4 mr-2" />
      ทัวร์จีน
    </Link>

    <Link to="/outbound/vietnam" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/vietnam.png" alt="Icon" className="w-4 h-4 mr-2" />
      ทัวร์เวียดนาม
    </Link>

    <Link to="/outbound/taiwan" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/taiwan.png" alt="Icon" className="w-4 h-4 mr-2" />
      ทัวร์ไต้หวัน
    </Link>

    <Link to="/outbound/korea" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/korea.png" alt="Icon" className="w-4 h-4 mr-2" />
      ทัวร์เกาหลี
    </Link>

    <Link to="/outbound/singapore" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/singapore.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สิงคโปร์
    </Link>

    <Link  to="/outbound/hongkong" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/hong-kong.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ฮ่องกง
    </Link>

    <Link  to="/outbound/macao" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/macao.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์มาเก๊า
    </Link>

    <Link  to="/outbound/malaysia" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/malaysia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์มาเลเซีย
    </Link>

    <Link  to="/outbound/guangzhou" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/china.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์กวางเจาเทรดแฟร์
    </Link>

    <Link to="/outbound/india" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/india.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อินเดีย
    </Link>

    <Link to="/outbound/myanmar" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/myanmar.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์พม่า
    </Link>
    <Link to="/outbound/laos" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/laos.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ลาว
    </Link>
    <Link to="/outbound/bhutan" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/bhutan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ภูฏาน
    </Link>
    <Link to="/outbound/bali" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/indonesia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์บาหลี
    </Link>
    <Link to="/outbound/dubai" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/Emirates.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ดูไบ
        </Link>
        <Link to="/outbound/jordan" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/jordan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์จอร์แดน
        </Link>
        <Link to="/outbound/egypt" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/egypt.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อียิปต์
        </Link>
  </div>
</nav>

<nav>
  <h6 className="footer-title">ทัวร์ทวีปยุโรป</h6>

  <div className="grid grid-cols-2 gap-4">
    <Link to="/outbound/europe"className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ยุโรป
    </Link>

    <Link to="/outbound/switzerland" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/switzerland.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สวิตเซอร์แลนด์
    </Link>

    <Link to="/outbound/france" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/france.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ฝรั่งเศส
    </Link>

    <Link to="/outbound/italy" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/italy.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อิตาลี
    </Link>

    <Link to="/outbound/england" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/england.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อังกฤษ
    </Link>

    <Link to="/outbound/spain" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/spain.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สเปน
    </Link>

    <Link  to="/outbound/germany" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/germany.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เยอรมัน
    </Link>

    <Link  to="/outbound/netherlands" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/netherlands.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เนเธอร์แลนด์
    </Link>

    <Link  to="/outbound/portugal" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/portugal.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์โปรตุเกส
    </Link>

    <Link  to="/outbound/scandinavian" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/scandinavian.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สแกนดิเนเวีย
    </Link>

    <Link to="/outbound/denmark" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/denmark.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เดนมาร์ก
    </Link>

    <Link to="/outbound/austria" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/austria.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ออสเตรีย
    </Link>
    <Link to="/outbound/norway" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/norway.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์นอร์เวย์
    </Link>
    <Link to="/outbound/iceland" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/iceland.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ไอซ์แลนด์
    </Link>
    <Link to="/outbound/sweden" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/sweden.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สวีเดน
    </Link>
    <Link to="/outbound/russia" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/russia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์รัสเซีย
        </Link>
        <Link to="/outbound/turkiye" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/Turkiye.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ตุรกี
        </Link>
        <Link to="/outbound/georgia" className="link link-hover hover:text-gray-500 flex items-center">
            <img src="/images/georgia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์จอร์เจีย
        </Link>
  </div>
</nav>

<nav>
  <h6 className="footer-title">ทัวร์ทวีปอื่นๆ</h6>

  <div className="grid grid-cols-1 gap-4">
  <Link to="/outbound/hotdeal" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/hotdeal.png" alt="Icon" className="w-4 h-4 mr-2" />
      โปรไฟไหม้
    </Link>
    <Link to="/outbound/europe" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
      ทัวร์ต่างประเทศ
    </Link>
    <Link to="/outbound/usa" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/united-states-of-america.png" alt="Icon" className="w-4 h-4 mr-2" />
      ทัวร์อเมริกา
    </Link>
    <Link to="/outbound/australia" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/australia-1.png" alt="Icon" className="w-4 h-4 mr-2" />
      ทัวร์ออสเตรเลีย
    </Link>
    <Link to="/outbound/new-zealand" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/new-zealand.png" alt="Icon" className="w-4 h-4 mr-2" />
      ทัวร์นิวซีแลนด์
    </Link>
    <Link to="/outbound/canada" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/canada.png" alt="Icon" className="w-4 h-4 mr-2" />
      ทัวร์แคนาดา
    </Link>
    <Link to="/outbound/africa" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/Africa.png" alt="Icon" className="w-4 h-4 mr-2" />
      ทัวร์แอฟริกา
    </Link>
    <Link to="/outbound/kazakhstan" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
      ทัวร์คาซัคสถาน
    </Link>
    <Link to="/outbound/thailand" className="link link-hover hover:text-gray-500 flex items-center">
      <img src="/images/thailand.png" alt="Icon" className="w-4 h-4 mr-2" />
      ทัวร์ในประเทศ
    </Link>
  </div>
</nav>


</footer>

</>
);

export default Footer;

import React, { useState } from 'react';
import { auth, db } from './firebase'; // Ensure you import the db from your firebase config
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'; // Import the signIn function
import { ref, set } from 'firebase/database'; // Import the database functions

const Register = () => {
  // State variables for user input
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Save additional user information in Realtime Database
      await set(ref(db, 'users/' + user.uid), {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
      });

      // Automatically sign in the user after registration
      await signInWithEmailAndPassword(auth, email, password);

      alert('Registration and Sign-In Successful');
      resetForm(); // Reset the form
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Error during registration:", error);
    }
  };

  // Function to reset form fields
  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setPhone('');
    setEmail('');
    setPassword('');
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-full max-w-xs">
        <form onSubmit={handleRegister}>
          <div className="flex justify-center mb-6">
            <img
              src="/images/TF_Logo-Color.png" 
              alt="Logo"
              className="h-16 w-auto" 
            />
          </div>

          {errorMessage && (
            <div className="mb-4 text-red-500 text-center">
              {errorMessage}
            </div>
          )}

          <label className="input input-bordered flex items-center gap-2 mb-3">
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              className="grow"
            />
          </label>

          <label className="input input-bordered flex items-center gap-2 mb-3">
            <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              className="grow"
            />
          </label>

          <label className="input input-bordered flex items-center gap-2 mb-3">
            <input
              type="tel"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              className="grow"
            />
          </label>

          <label className="input input-bordered flex items-center gap-2 mb-3">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="grow"
            />
          </label>

          <label className="input input-bordered flex items-center gap-2 mb-3">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="grow"
            />
          </label>

          <button className="btn w-full mb-2" type="submit">Register</button>
          <div className="text-center">
            <p className="text-sm">Already have an account? <a href="/login" className="text-blue-500 hover:underline">Sign In</a></p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;

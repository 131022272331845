// App.js
import React from 'react';
import { UserProvider } from './contexts/UserContext';
import Navbar from './components/Navbar2';
import Detail from './page/outbound/detail/page';

function App() {
  return (
    <UserProvider>
      <Navbar />
      <Detail />
    </UserProvider>
  );
}

export default App;
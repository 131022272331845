// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Home from './page/home/home';
import Landing from './page/outbound/landing/page';
import City from './page/outbound/landing/city';
import Citys from './page/outbound/landing/city/page';
import Detail from './page/outbound/detail/page';
import FormPage from './page/outbound/detail/FormPage';
import Search from './page/search/page';
import Navbar2 from './components/Navbar2';
import Login from './components/firebase/Login';
import Register from './components/firebase/Register';
import { Helmet } from 'react-helmet';
import './index.css';
import '@fontsource/prompt';
import { UserProvider } from './contexts/UserContext';

// Create a root
const root = createRoot(document.getElementById('root'));

// Render the application
root.render(
  <UserProvider>
    <Router>
      <Routes>
        {/* Route for the root path */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/search" element={<Search />} />
        {/* Route with dynamic segment */}
        <Route path="/outbound/:landing" element={<Landing />} />
        <Route path="/outbound/:landing/:city" element={<City />} />
        <Route path="/outbound/:landing/:city" element={<Citys />} />
        <Route path="/outbound/tour/:model" element={<Detail />} />
        <Route path="/outbound/tour/:model/form-booking" element={<FormPage />} />
      </Routes>
    </Router>
  </UserProvider>
);
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ProductList = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch('/api/thaifly/program/japan')
      .then((response) => response.json())
      .then((data) => {
        if (data.product && Array.isArray(data.product)) {
          const shuffledProducts = data.product.sort(() => 0.5 - Math.random());
          const selectedProducts = shuffledProducts.slice(0, 8); // Display 8 products (2 rows of 4)
          setProducts(selectedProducts);
        }
      })
      .catch((error) => console.error('Error fetching product data:', error));
  }, []);

  return (
    <div className="container mx-auto px-4 mt-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {products.map((product) => (
          <div
            key={product.product_id}
            className="bg-white border border-gray-200 rounded-xl shadow-md hover:shadow-xl transition-shadow duration-200"
          >
            {/* Image Section */}
          <a href={`/outbound/tour/${product.model}`} target="_blank" rel="noopener noreferrer">
              <div className="flex justify-center overflow-hidden rounded-t-xl">
                <img
                  src={product.popup}
                  alt={product.name}
                  className="w-full h-full object-contain"
                />
              </div>
            </a>

            {/* Details Section */}
            <div className="p-4">
            <span className="bg-yellow-100 text-yellow-800 text-xs 
                                    font-medium me-2 px-2.5 py-0.5 rounded-full">{product.model}</span>
              <h3 className=" text-l sm:text-sm md:text-lg font-semibold text-blue-500 overflow-hidden line-clamp-2 mb-2">
                {product.name}
              </h3>
              <div className="text-sm text-gray-500 mb-2">
                <span>ช่วงเดินทาง: {product.period_month}</span>
              </div>
              <div className="flex justify-between text-sm text-gray-700 mb-4">
  {/* จำนวนวัน Section */}
  <div className="flex flex-col items-start">
    <span className="text-xs text-gray-500">จำนวนวัน</span>
    <span>{product.day} วัน | {product.night} คืน</span>
  </div>

  <div className="border-r-2 border-gray-200"></div>


  {/* สายการบิน Section */}
  <div className="flex flex-col items-end">
    <span className="text-xs text-gray-500">สายการบิน</span>
    <img
      src={product.airline_icon}
      alt={product.airline_code}
      className="w-auto h-6 rounded-lg"
    />
  </div>
</div>



<div className="flex justify-between items-center">
  <span className="text-xl font-semibold text-red-600">
    {Number(product.price).toLocaleString()} บาท
  </span>
  <Link
                to={`/outbound/tour/${product.model}`}
                target="_blank"
                className="flex items-center bg-blue-600 text-white hover:bg-blue-700 transition duration-200 px-3 py-2 sm:px-2 sm:py-1 md:px-1 md:py-0.5 rounded"
              >
                <span className="mr-2 text-base sm:text-sm md:text-xs">ดูเพิ่มเติม</span>
                <svg
                  className="w-4 h-4 sm:w-3 sm:h-3 md:w-2 md:h-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path stroke="currentColor" d="M1 5h12m0 0L9 1m4 4L9 9" />
                </svg>
              </Link>
</div>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;

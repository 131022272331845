import React from 'react';

const Badge = ({ label, icon, onClear }) => (
  <div className="flex items-center bg-white border border-gray-200 rounded-full px-3 py-1">
    {/* Display the icon if provided */}
    {icon && (
      <img 
        src={icon} 
        alt={label} 
        className="w-full h-6 mr-2 rounded-lg" 
      />
    )}
    <span className="text-xs font-medium text-blue-600 mr-2">{label}</span>
    <button 
      onClick={onClear} 
      className="inline-flex justify-center items-center rounded-full text-gray-800 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
    >
      <svg 
        width="16" 
        height="16" 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        className="text-blue-600"
      >
        <path d="M18 6L6 18"></path>
        <path d="M6 6l12 12"></path>
      </svg>
    </button>
  </div>
);

export default Badge;

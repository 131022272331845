import React, { useState, useEffect, useRef, useContext } from 'react';
import { auth, db, googleProvider, facebookProvider } from '../components/firebase/firebase'; // ปรับเส้นทางตามที่จำเป็น
import { onAuthStateChanged } from 'firebase/auth';
import { onValue, ref } from 'firebase/database';
import Swal from 'sweetalert2';
import { a, useNavigate } from 'react-router-dom';
import { signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import Register from '../components/firebase/Register';
import Login from '../components/firebase/Login';
import { UserContext } from '../contexts/UserContext';

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const menuRef = useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);
  const { user, setUser } = useContext(UserContext);
  const [firstName, setFirstName] = useState(''); // สถานะสำหรับชื่อ
  const [lastName, setLastName] = useState(''); // สถานะสำหรับนามสกุล
  const googleProvider = new GoogleAuthProvider();
  const [isOpen, setIsOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOffcanvasNavOpen, setIsOffcanvasNavOpen] = useState(false);
  const [navbarVisible, setNavbarVisible] = useState(true);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const lastScroll = useRef(0);
  
      // การจัดการการเลื่อนหน้าจอสำหรับ navbar และปุ่มเลื่อนขึ้นด้านบน
      useEffect(() => {
        const handleScroll = () => {
            const currentScroll = window.pageYOffset;
            if (currentScroll <= 0) {
                setNavbarVisible(true);
                setShowScrollToTop(false);
                return;
            }

            if (currentScroll > lastScroll.current) {
                setNavbarVisible(false);
            } else {
                setNavbarVisible(true);
            }

            setShowScrollToTop(currentScroll > 300);

            lastScroll.current = currentScroll;
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


  const toggleOffcanvas = () => {
    setIsOffcanvasNavOpen(prevState => !prevState); // Toggle between open/closed
  };
  
  const closeOffcanvas = () => {
    setIsOffcanvasNavOpen(false); // Explicitly close the off-canvas
  };
  


  useEffect(() => {
      const handleScroll = () => {
          const currentScrollY = window.scrollY;
          if (currentScrollY > 0) {
              setIsScrolled(true);
          } else {
              setIsScrolled(false);
          }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log('User signed in with Google:', result.user);
      Swal.fire(`Welcome ${result.user.displayName}`, '', 'success');
      navigate(0);  // Go back to the previous page
    } catch (error) {
      console.error("Error signing in with Google:", error);
      setErrorMessage(error.message);
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          Swal.fire({
            title: 'Attention!',
            text: 'Please complete the login process before closing the popup.',
            icon: 'warning',
            confirmButtonText: 'Okay',
          });
          break;
        default:
          Swal.fire('Login Failed', error.message, 'error');
      }
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
        setUser(user);
      } else {
        setIsLoggedIn(false);
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleGoogleOneTap = () => {
    window.google.accounts.id.initialize({
      client_id: '866510304186-92lcbge16g6206vt51mfa1u2m76lbk3g.apps.googleusercontent.com',
      callback: handleCredentialResponse,
      auto_select: true,
      cancel_on_tap_outside: false,
    });

    window.google.accounts.id.prompt();
  };

  

  const handleCredentialResponse = (response) => {
    const credential = response.credential;

    // ใช้งาน credential เพื่อเข้าสู่ระบบ
    auth.signInWithCredential(credential).then((result) => {
      setIsLoggedIn(true);
      setUser(result.user);
    }).catch((error) => {
      console.error("Error signing in: ", error);
    });
  };


  // Monitor authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUserEmail(user.email);
        setUser({
          displayName: user.displayName || user.email.split('@')[0],
          photoURL: user.photoURL,
        });
        fetchUserName(user.email); // Fetch user's name from the database
      } else {
        setIsLoggedIn(false);
        setUserEmail('');
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

// Handle Google Login
  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const userData = result.user;

      setUserName(userData.displayName || userData.email);
      setUser({
        displayName: userData.displayName || userData.email,
        photoURL: userData.photoURL,
      });
      setUserEmail(userData.email);
      setIsLoggedIn(true);

      if (modalRef.current) {
        modalRef.current.close();
      }
      
      Swal.fire('Login Successful', `Welcome, ${userData.displayName || 'User'}!`, 'success');
    } catch (error) {
      console.error("Error during Google login:", error);
      
      // จัดการข้อผิดพลาด
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          Swal.fire({
            title: 'Attention!',
            text: 'Please complete the login process before closing the popup.',
            icon: 'warning',
            confirmButtonText: 'Okay',
          });
          break;
        case 'auth/network-request-failed':
          Swal.fire('Network Error', 'Please check your internet connection and try again.', 'error');
          break;
        default:
          Swal.fire('Login Failed', error.message, 'error');
      }
    }
  };


  // Handle Facebook Login
  const handleFacebookLogin = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      const userData = result.user;

      // Store user name and profile picture
      setUserName(userData.displayName || userData.email);
      setUser({
        displayName: userData.displayName || userData.email,
        photoURL: userData.photoURL,
      });
      setUserEmail(userData.email);
      setIsLoggedIn(true);

      // Close modal after successful login
      if (modalRef.current) {
        modalRef.current.close();
      }

      // Optionally fetch user's name from the database if needed
      fetchUserName(userData.email);
    } catch (error) {
      console.error("Error during Facebook login:", error);
    }
  };

  // Handle Email/Password Login
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userData = userCredential.user;

      // Set user state and email
      setUser({
        displayName: email.split('@')[0], // Optionally set display name
        photoURL: null,
      });
      setUserEmail(userData.email);
      setIsLoggedIn(true);

      // Fetch user's name and details
      fetchUserName(userData.email);

      Swal.fire('Login Successful', '', 'success');
      if (modalRef.current) {
        modalRef.current.close(); // Close modal after successful login
      }
    } catch (error) {
      Swal.fire('Login Failed', error.message, 'error');
      if (modalRef.current) {
        modalRef.current.close();
      }
    } finally {
      setLoading(false);
    }
  };

  // Fetch user's name and surname from Firebase
  const fetchUserName = (userEmail) => {
    const usersRef = ref(db, 'users');

    onValue(usersRef, (snapshot) => {
      const users = snapshot.val();
      if (users) {
        const user = Object.values(users).find((user) => user.email === userEmail);
        if (user) {
          setFirstName(user.firstName);
          setLastName(user.lastName);
          setUser({
            displayName: `${user.firstName} ${user.lastName}`, // Update displayName
            photoURL: user.photoURL || null, // Set profile picture if available
          });
        } else {
          setFirstName('');
          setLastName('');
        }
      }
    });
  };

  // Handle Logout
  const handleLogout = () => {
    auth.signOut().then(() => {
      setUser(null);
      setIsLoggedIn(false); // Make sure to update the logged-in state
      Swal.fire('Logged Out', '', 'info');
    });
  };

  // Close dropdowns when clicking outside
  const closeDropdowns = () => {
    const details = menuRef.current?.querySelector('details');
    if (details && details.open) {
      details.open = false;
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      closeDropdowns();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  

  return (
        <> 
                        <div className={`${navbarVisible ? 'block' : 'hidden'} border-b border-gray-300 fixed top-0 left-0 right-0 z-50`}>   
<div className="navbar bg-white backdrop-blur-md top-0 left-0 right-0  z-50">
    <div className="navbar-start">
            <button className="btn btn-ghost lg:hidden"
            aria-expanded={isOffcanvasNavOpen}
            onClick={toggleOffcanvas}
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
                </svg>
            </button>

        <div className="btn btn-ghost text-xl">
            <a href="/" className="flex items-center">
                <img src="/images/TF_Logo-Color.png" width={1000} height={1000} alt="Logo" className="h-12 w-auto" />
            </a>
        </div>
    </div>

    {/* desktop view */}
    <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
    <li className="relative dropdown" ref={menuRef}>
      <details>
        <summary className="m-1 cursor-pointer">ทัวร์ต่างประเทศ</summary>
        <ul className="menu xl:menu-horizontal left-1/2 transform -translate-x-1/2 rounded-xl lg:min-w-max max-w-lg w-full" style={{ top: '4rem', marginLeft: '230px' }}>
    <li>
        <h1 className="text-lg font-bold text-center">ทวีปเอเซีย</h1>
        <div className="flex flex-wrap gap-4">
            <ul className="flex flex-col flex-grow">
            <li>
        <a href="/outbound/japan" className="hover:text-gray-500 flex items-center" 
        onClick={closeDropdowns}>
            <img src="/images/japan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ญี่ปุ่น
        </a>
    </li>
    <li>
        <a href="/outbound/china" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/china.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์จีน
        </a>
    </li>
    <li>
        <a href="/outbound/vietnam" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/vietnam.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เวียดนาม
        </a>
    </li>
    <li>
        <a href="/outbound/taiwan" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/taiwan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ไต้หวัน
        </a>
    </li>
    <li>
        <a href="/outbound/korea" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/korea.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เกาหลี
        </a>
    </li>
    <li>
        <a href="/outbound/singapore" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/singapore.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สิงคโปร์
        </a>
    </li>
    <li>
        <a href="/outbound/hongkong" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/hong-kong.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ฮ่องกง
        </a>
    </li>
    <li>
        <a href="/outbound/macao" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/macao.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์มาเก๊า
        </a>
    </li>
    <li>
        <a href="/outbound/malaysia" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/malaysia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์มาเลเซีย
        </a>
    </li>

            </ul>
            <ul className="flex flex-col flex-grow">
            <li>
        <a href="/outbound/guangzhou" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/china.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์กวางเจาเทรดแฟร์
        </a>
    </li>
    <li>
        <a href="/outbound/india" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/india.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อินเดีย
        </a>
    </li>
    <li>
        <a href="/outbound/myanmar" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/myanmar.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์พม่า
        </a>
    </li>
    <li>
        <a href="/outbound/laos" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/laos.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ลาว
        </a>
    </li>
    <li>
        <a href="/outbound/bhutan" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/bhutan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ภูฏาน
        </a>
    </li>
    <li>
        <a href="/outbound/bali" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/indonesia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์บาหลี
        </a>
    </li>
    <li>
        <a href="/outbound/dubai" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/Emirates.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ดูไบ
        </a>
    </li>
    <li>
        <a href="/outbound/jordan" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/jordan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์จอร์แดน
        </a>
    </li>
    <li>
        <a href="/outbound/egypt" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/egypt.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อียิปต์
        </a>
    </li>
            </ul>
        </div>
    </li>
    
    <li>
    <h1 className="text-lg font-bold text-center">ทวีปยุโรป</h1>
    <div className="flex flex-wrap gap-4">
        <ul className="flex flex-col flex-grow">
            <li>
                <a href="/outbound/europe" className="hover:text-gray-500 flex items-center">
                    <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์ยุโรป
                </a>
            </li>
            <li>
                <a href="/outbound/switzerland" className="hover:text-gray-500 flex items-center">
                    <img src="/images/switzerland.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์สวิตเซอร์แลนด์
                </a>
            </li>
            <li>
                <a href="/outbound/france" className="hover:text-gray-500 flex items-center">
                    <img src="/images/france.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์ฝรั่งเศส
                </a>
            </li>
            <li>
                <a href="/outbound/italy" className="hover:text-gray-500 flex items-center">
                    <img src="/images/italy.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์อิตาลี
                </a>
            </li>
            <li>
                <a href="/outbound/england" className="hover:text-gray-500 flex items-center">
                    <img src="/images/england.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์อังกฤษ
                </a>
            </li>
            <li>
                <a href="/outbound/spain" className="hover:text-gray-500 flex items-center">
                    <img src="/images/spain.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์สเปน
                </a>
            </li>
            <li>
                <a href="/outbound/germany" className="hover:text-gray-500 flex items-center">
                    <img src="/images/germany.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์เยอรมัน
                </a>
            </li>
            <li>
                <a href="/outbound/netherlands" className="hover:text-gray-500 flex items-center">
                    <img src="/images/netherlands.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์เนเธอร์แลนด์
                </a>
            </li>
            <li>
                <a href="/outbound/portugal" className="hover:text-gray-500 flex items-center">
                    <img src="/images/portugal.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์โปรตุเกส
                </a>
            </li>
        </ul>
        <ul className="flex flex-col flex-grow">
            <li>
                <a href="/outbound/scandinavia" className="hover:text-gray-500 flex items-center">
                    <img src="/images/scandinavian.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์สแกนดิเนเวีย
                </a>
            </li>
            <li>
                <a href="/outbound/denmark" className="hover:text-gray-500 flex items-center">
                    <img src="/images/denmark.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์เดนมาร์ก
                </a>
            </li>
            <li>
                <a href="/outbound/austria" className="hover:text-gray-500 flex items-center">
                    <img src="/images/austria.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์ออสเตรีย
                </a>
            </li>
            <li>
                <a href="/outbound/norway" className="hover:text-gray-500 flex items-center">
                    <img src="/images/norway.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์นอร์เวย์
                </a>
            </li>
            <li>
                <a href="/outbound/iceland" className="hover:text-gray-500 flex items-center">
                    <img src="/images/iceland.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์ไอซ์แลนด์
                </a>
            </li>
            <li>
                <a href="/outbound/sweden" className="hover:text-gray-500 flex items-center">
                    <img src="/images/sweden.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์สวีเดน
                </a>
            </li>
            <li>
                <a href="/outbound/russia" className="hover:text-gray-500 flex items-center">
                    <img src="/images/russia.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์รัสเซีย
                </a>
            </li>
            <li>
                <a href="/outbound/turkey" className="hover:text-gray-500 flex items-center">
                    <img src="/images/Turkiye.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์ตุรกี
                </a>
            </li>
            <li>
                <a href="/outbound/georgia" className="hover:text-gray-500 flex items-center">
                    <img src="/images/georgia.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์จอร์เจีย
                </a>
            </li>
        </ul>
    </div>
</li>


<li>
  <h1 className="text-lg font-bold text-center">ทวีปอื่นๆ</h1>
  <div className="flex flex-wrap gap-4">
    <ul className="flex flex-col flex-grow">
      <li>
        <a href="/outbound/international" className="hover:text-gray-500 flex items-center">
          <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์ต่างประเทศ
        </a>
      </li>
      <li>
        <a href="/outbound/america" className="hover:text-gray-500 flex items-center">
          <img src="/images/united-states-of-america.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์อเมริกา
        </a>
      </li>
      <li>
        <a href="/outbound/australia" className="hover:text-gray-500 flex items-center">
          <img src="/images/australia-1.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์ออสเตรเลีย
        </a>
      </li>
      <li>
        <a href="/outbound/newzealand" className="hover:text-gray-500 flex items-center">
          <img src="/images/new-zealand.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์นิวซีแลนด์
        </a>
      </li>
      <li>
        <a href="/outbound/canada" className="hover:text-gray-500 flex items-center">
          <img src="/images/canada.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์แคนาดา
        </a>
      </li>
      <li>
        <a href="/outbound/africa" className="hover:text-gray-500 flex items-center">
          <img src="/images/Africa.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์แอฟริกา
        </a>
      </li>
      <li>
        <a href="/outbound/kazakhstan" className="hover:text-gray-500 flex items-center">
          <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์คาซัคสถาน
        </a>
      </li>
      <li>
        <a href="/outbound/thailand" className="hover:text-gray-500 flex items-center">
          <img src="/images/thailand.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์ในประเทศ
        </a>
      </li>
      <li>
        <a href="/outbound/hotdeal" className="hover:text-gray-500 flex items-center">
          <img src="/images/hotdeal.png" alt="Icon" className="w-4 h-4 mr-2" />
          โปรไฟไหม้
        </a>
      </li>
    </ul>
  </div>
</li>


</ul>


                </details>
            </li>
            <li className={`m-1 cursor-pointer ${isScrolled ? 'text-black' : 'text-black'}`}><a>จองตั๋วเครื่องบิน</a></li>
            <li className={`m-1 cursor-pointer ${isScrolled ? 'text-black' : 'text-black'}`}><a>ทีมบิ้วดิ้ง</a></li>
            <li className={`m-1 cursor-pointer ${isScrolled ? 'text-black' : 'text-black'}`}><a>เกี่ยวกับเรา</a></li>
            <li className={`m-1 cursor-pointer ${isScrolled ? 'text-black' : 'text-black'}`}><a href="https://thaifly.com/article" >ข้อมูลท่องเที่ยว</a></li>

        </ul>
    </div>


    <div className="navbar-end">





    <div className="dropdown dropdown-end">
    <div className="dropdown">
  <button tabIndex={0} className="btn btn-ghost btn-circle avatar mr-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className="inline-block h-5 w-5 stroke-current">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path>
    </svg>
  </button>
  <ul tabIndex={0} 
  className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 lg:w-[270px] p-2 shadow w-[270px]"
  style={{ top: '3rem'}}
  >
        <li className='mb-2'>
    <label className="input input-bordered flex items-center gap-2">
  <input type="text" className="grow" placeholder="Search" />
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="currentColor"
    className="h-4 w-4 opacity-70">
    <path
      fillRule="evenodd"
      d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
      clipRule="evenodd" />
  </svg>
</label>
    </li>
    <li><a>ประวัติการจองทัวร์</a></li>


  </ul>
</div>

    
  </div>


             {/* Button to open modal */}
             <div>
      {/* Button to open modal */}
{/* Button to open modal */}
<div className="dropdown dropdown-end">
      {!isLoggedIn && (
        <a href="/login">
  <button className="btn">
    Sign In / Sign Up
  </button>
</a>

      )}

      {/* การ์ดสำหรับ Sign in with Google */}

    </div>




<div className="dropdown dropdown-end">
  {isLoggedIn && (
    <div className="flex items-center" tabIndex={0} role="button">
      <div className="btn btn-ghost btn-circle avatar">
        {user?.photoURL ? (
          <img src={user.photoURL} alt={userName} className="w-8 h-8 rounded-full" />
        ) : (
          <div className="w-10 h-10 rounded-full flex items-center justify-center bg-gray-200 text-gray-800">
            <span className="text-xl font-semibold" style={{ lineHeight: '2.5rem' }}>
              {user.displayName.charAt(0)}
            </span>
          </div>
        )}
      </div>
    </div>
  )}

  {isLoggedIn && (
    <ul
      tabIndex={0}
      className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 lg:w-[250px] p-2 shadow w-[250px]"
      style={{ top: '3rem' }}
    >
      <li>
        <a className="flex items-center justify-between">
          <div className="flex items-center mb-1">
            {user?.photoURL ? (
              // Show profile picture from Google or Facebook if available
              <img src={user.photoURL} alt={userName} className="w-7 h-7 rounded-full mr-2" />
            ) : (
              // Show first character of user name if no profile picture
              <div className="w-7 h-7 rounded-full flex items-center justify-center bg-gray-200 text-gray-800 mr-2">
                <span className="text-sm font-semibold">{user.displayName.charAt(0)}</span>
              </div>
            )}
            <div className="flex flex-col"> {/* Added flex-col to stack vertically */}
              <span className="text-sm">{user.displayName || user.firstName}</span> {/* Show user name */}
              {userEmail && (
                <span className="text-xs text-gray-500">{userEmail}</span> // Show email
              )}
            </div>
          </div>
        </a>
      </li>

      <li>
        <a className="flex items-center" onClick={handleLogout}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 17h-6a2 2 0 01-2-2v-4a2 2 0 012-2h6m3 4l4-4m0 0l-4-4m4 4H3"
            />
          </svg>
          Logout
        </a>
      </li>
    </ul>
  )}
</div>

</div>




    </div>

</div>

</div>

{isOffcanvasNavOpen && (
  <>
    {/* Blurred background overlay */}
    <div 
      className="fixed inset-0 bg-white bg-opacity-30 backdrop-blur-sm z-[70] transition-opacity duration-300" 
      onClick={closeOffcanvas}
    ></div>

    {/* Off-canvas menu */}
    <div
      id="hs-offcanvas-body-scrolling-with-backdrop"
      className={`fixed top-0 left-0 transform transition-transform duration-300 ease-in-out h-full max-w-xs w-full z-[80] bg-white border-e ${
        isOffcanvasNavOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
      role="dialog"
      tabIndex="-1"
      aria-labelledby="hs-offcanvas-body-scrolling-with-backdrop-label"
    >
      <div className="flex justify-between items-center py-3 px-4 border-b bg-gray-100">
        <div className="text-xl font-semibold">
          <a href="/" className="flex items-center">
            <img src="/images/TF_Logo-Color.png" width={1000} height={1000} alt="Logo" className="h-10 w-auto" />
          </a>
        </div>
        {/* Close button */}
        <button
          type="button"
          onClick={closeOffcanvas}
          className="inline-flex items-center justify-center p-2 rounded-full bg-gray-200 text-gray-800 hover:bg-gray-300 focus:outline-none"
          aria-label="Close"
        >
          <svg
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M18 6L6 18"></path>
            <path d="M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      
      {/* Off-canvas menu content with scrollable area */}
      <div className="p-4 h-[calc(100vh-60px)] overflow-y-auto">
        <ul className="menu menu-vertical space-y-4 text-gray-700">
          
          {/* Section: Explore the World */}
          <li className="text-xs font-semibold text-gray-500">Explore the World</li>
          
          <li>
            <a href="/outbound" className="flex items-center hover:text-blue-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-add" viewBox="0 0 16 16">
            <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h4a.5.5 0 1 0 0-1h-4a.5.5 0 0 1-.5-.5V7.207l5-5 6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293z"/>
            <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 1 0 1 0v-1h1a.5.5 0 1 0 0-1h-1v-1a.5.5 0 0 0-.5-.5"/>
          </svg>
              หน้าหลัก
            </a>
          </li>
          
          {/* Asia Section */}
          <div className="join join-vertical w-full border-t border-gray-200 pt-4">
    
          {/* Asia Section */}
          <div className="collapse collapse-arrow join-item">
            <input type="checkbox" className="peer" defaultChecked/>
            <div className="collapse-title text-l font-semibold text-gray-600 peer-checked:bg-gray-100">
              ทวีปเอเซีย
            </div>
            <div className="collapse-content">
            <ul className="pl-4">
          <li>
        <a href="/outbound/japan" className="hover:text-gray-500 flex items-center" 
        onClick={closeDropdowns}>
            <img src="/images/japan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ญี่ปุ่น
        </a>
    </li>
    <li>
        <a href="/outbound/china" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/china.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์จีน
        </a>
    </li>
    <li>
        <a href="/outbound/vietnam" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/vietnam.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เวียดนาม
        </a>
    </li>
    <li>
        <a href="/outbound/taiwan" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/taiwan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ไต้หวัน
        </a>
    </li>
    <li>
        <a href="/outbound/korea" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/korea.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เกาหลี
        </a>
    </li>
    <li>
        <a href="/outbound/singapore" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/singapore.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สิงคโปร์
        </a>
    </li>
    <li>
        <a href="/outbound/hongkong" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/hong-kong.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ฮ่องกง
        </a>
    </li>
    <li>
        <a href="/outbound/macao" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/macao.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์มาเก๊า
        </a>
    </li>
    <li>
        <a href="/outbound/malaysia" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/malaysia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์มาเลเซีย
        </a>
    </li>

 
            <li>
        <a href="/outbound/guangzhou" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/china.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์กวางเจาเทรดแฟร์
        </a>
    </li>
    <li>
        <a href="/outbound/india" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/india.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อินเดีย
        </a>
    </li>
    <li>
        <a href="/outbound/myanmar" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/myanmar.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์พม่า
        </a>
    </li>
    <li>
        <a href="/outbound/laos" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/laos.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ลาว
        </a>
    </li>
    <li>
        <a href="/outbound/bhutan" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/bhutan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ภูฏาน
        </a>
    </li>
    <li>
        <a href="/outbound/bali" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/indonesia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์บาหลี
        </a>
    </li>
    <li>
        <a href="/outbound/dubai" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/Emirates.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ดูไบ
        </a>
    </li>
    <li>
        <a href="/outbound/jordan" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/jordan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์จอร์แดน
        </a>
    </li>
    <li>
        <a href="/outbound/egypt" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/egypt.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อียิปต์
        </a>
    </li>
          

              </ul>
            </div>
          </div>
          
          {/* Europe Section */}
          <div className="collapse collapse-arrow join-item mt-4">
            <input type="checkbox" className="peer" />
            <div className="collapse-title text-l font-semibold text-gray-600 peer-checked:bg-gray-100">
              ทวีปยุโรป
            </div>
            <div className="collapse-content">
              <ul className="pl-4">
            <li>
                <a href="/outbound/europe" className="hover:text-gray-500 flex items-center">
                    <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์ยุโรป
                </a>
            </li>
            <li>
                <a href="/outbound/switzerland" className="hover:text-gray-500 flex items-center">
                    <img src="/images/switzerland.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์สวิตเซอร์แลนด์
                </a>
            </li>
            <li>
                <a href="/outbound/france" className="hover:text-gray-500 flex items-center">
                    <img src="/images/france.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์ฝรั่งเศส
                </a>
            </li>
            <li>
                <a href="/outbound/italy" className="hover:text-gray-500 flex items-center">
                    <img src="/images/italy.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์อิตาลี
                </a>
            </li>
            <li>
                <a href="/outbound/england" className="hover:text-gray-500 flex items-center">
                    <img src="/images/england.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์อังกฤษ
                </a>
            </li>
            <li>
                <a href="/outbound/spain" className="hover:text-gray-500 flex items-center">
                    <img src="/images/spain.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์สเปน
                </a>
            </li>
            <li>
                <a href="/outbound/germany" className="hover:text-gray-500 flex items-center">
                    <img src="/images/germany.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์เยอรมัน
                </a>
            </li>
            <li>
                <a href="/outbound/netherlands" className="hover:text-gray-500 flex items-center">
                    <img src="/images/netherlands.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์เนเธอร์แลนด์
                </a>
            </li>
            <li>
                <a href="/outbound/portugal" className="hover:text-gray-500 flex items-center">
                    <img src="/images/portugal.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์โปรตุเกส
                </a>
            </li>
            <li>
                <a href="/outbound/scandinavia" className="hover:text-gray-500 flex items-center">
                    <img src="/images/scandinavian.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์สแกนดิเนเวีย
                </a>
            </li>
            <li>
                <a href="/outbound/denmark" className="hover:text-gray-500 flex items-center">
                    <img src="/images/denmark.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์เดนมาร์ก
                </a>
            </li>
            <li>
                <a href="/outbound/austria" className="hover:text-gray-500 flex items-center">
                    <img src="/images/austria.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์ออสเตรีย
                </a>
            </li>
            <li>
                <a href="/outbound/norway" className="hover:text-gray-500 flex items-center">
                    <img src="/images/norway.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์นอร์เวย์
                </a>
            </li>
            <li>
                <a href="/outbound/iceland" className="hover:text-gray-500 flex items-center">
                    <img src="/images/iceland.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์ไอซ์แลนด์
                </a>
            </li>
            <li>
                <a href="/outbound/sweden" className="hover:text-gray-500 flex items-center">
                    <img src="/images/sweden.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์สวีเดน
                </a>
            </li>
            <li>
                <a href="/outbound/russia" className="hover:text-gray-500 flex items-center">
                    <img src="/images/russia.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์รัสเซีย
                </a>
            </li>
            <li>
                <a href="/outbound/turkey" className="hover:text-gray-500 flex items-center">
                    <img src="/images/Turkiye.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์ตุรกี
                </a>
            </li>
            <li>
                <a href="/outbound/georgia" className="hover:text-gray-500 flex items-center">
                    <img src="/images/georgia.png" alt="Icon" className="w-4 h-4 mr-2" />
                    ทัวร์จอร์เจีย
                </a>
            </li>

              </ul>
            </div>
          </div>
        
          {/* Other Regions */}
          <div className="collapse collapse-arrow join-item mt-4">
            <input type="checkbox" className="peer" />
            <div className="collapse-title text-l font-semibold text-gray-600 peer-checked:bg-gray-100">
              ทวีปอื่นๆ
            </div>
            <div className="collapse-content">
            <ul className="pl-4">
            <li>
        <a href="/outbound/international" className="hover:text-gray-500 flex items-center">
          <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์ต่างประเทศ
        </a>
      </li>
      <li>
        <a href="/outbound/america" className="hover:text-gray-500 flex items-center">
          <img src="/images/united-states-of-america.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์อเมริกา
        </a>
      </li>
      <li>
        <a href="/outbound/australia" className="hover:text-gray-500 flex items-center">
          <img src="/images/australia-1.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์ออสเตรเลีย
        </a>
      </li>
      <li>
        <a href="/outbound/newzealand" className="hover:text-gray-500 flex items-center">
          <img src="/images/new-zealand.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์นิวซีแลนด์
        </a>
      </li>
      <li>
        <a href="/outbound/canada" className="hover:text-gray-500 flex items-center">
          <img src="/images/canada.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์แคนาดา
        </a>
      </li>
      <li>
        <a href="/outbound/africa" className="hover:text-gray-500 flex items-center">
          <img src="/images/Africa.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์แอฟริกา
        </a>
      </li>
      <li>
        <a href="/outbound/kazakhstan" className="hover:text-gray-500 flex items-center">
          <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์คาซัคสถาน
        </a>
      </li>
      <li>
        <a href="/outbound/thailand" className="hover:text-gray-500 flex items-center">
          <img src="/images/thailand.png" alt="Icon" className="w-4 h-4 mr-2" />
          ทัวร์ในประเทศ
        </a>
      </li>
      <li>
        <a href="/outbound/hotdeal" className="hover:text-gray-500 flex items-center">
          <img src="/images/hotdeal.png" alt="Icon" className="w-4 h-4 mr-2" />
          โปรไฟไหม้
        </a>
      </li>
      </ul>
            </div>
          </div>
        </div>

        {/* Additional Links */}
        <li className="mt-6">
          <a href="/flight-booking" className="flex items-center hover:text-blue-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ticket-detailed" viewBox="0 0 16 16">
              <path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M5 7a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2z"/>
              <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6zM1.5 4a.5.5 0 0 0-.5.5v1.05a2.5 2.5 0 0 1 0 4.9v1.05a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1.05a2.5 2.5 0 0 1 0-4.9V4.5a.5.5 0 0 0-.5-.5z"/>
            </svg>
            จองตั๋วเครื่องบิน
          </a>
        </li>
        <li>
          <a href="/team-building" className="flex items-center hover:text-blue-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16">
              <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>
            </svg>
            ทีมบิ้วดิ้ง
          </a>
        </li>
        <li>
          <a href="/about-us" className="flex items-center hover:text-blue-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-person" viewBox="0 0 16 16">
              <path d="M12 1a1 1 0 0 1 1 1v10.755S12 11 8 11s-5 1.755-5 1.755V2a1 1 0 0 1 1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
              <path d="M8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
            </svg>
            เกี่ยวกับเรา
          </a>
        </li>
        <li>
          <a href="https://thaifly.com/article" className="flex items-center hover:text-blue-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-suitcase-lg" viewBox="0 0 16 16">
              <path d="M5 2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2h3.5A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5H14a.5.5 0 0 1-1 0H3a.5.5 0 0 1-1 0h-.5A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2zm1 0h4a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1M1.5 3a.5.5 0 0 0-.5.5v1.05a2.5 2.5 0 0 1 0 4.9v1.05a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1.05a2.5 2.5 0 0 1 0-4.9V4.5a.5.5 0 0 0-.5-.5z"/>
            </svg>
            ข้อมูลท่องเที่ยว
          </a>
        </li>
      </ul>
    </div>
  </div>
  </>
)}






        </>
    );
};

export default Navbar;

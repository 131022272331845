import React, { lazy, Suspense, useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../../../components/firebase/firebase'; 
import { ref, set } from 'firebase/database';
const Navbar = lazy(() => import('../../../components/Navbar4'));
const Footer = lazy(() => import('../../../components/Footer'));
const Loading = lazy(() => import('../../../components/Loading'));
const CalendarComponent = lazy(() => import('../../../components/CalendarComponent'));


const Detail = () => {
    const { landing, model } = useParams(); 
    const [programDetail, setProgramDetail] = useState(null);
    const [landingData, setLandingData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [lineId, setLineId] = useState('');
    const [message, setMessage] = useState('');
    
    const [navbarVisible, setNavbarVisible] = useState(true);
    const lastScroll = useRef(0);

    const [manufacturer_ref_id, setManufacturer_ref_id] = useState(0);
    const [country, setCountry] = useState('');
    const [program_title, setProgram_title] = useState('');
    const [product_code, setProduct_code] = useState('');
    const [departuredate, setDeparturedate] = useState('');
    const [returndate, setReturndate] = useState('');

    const [price_adult, setPrice_adult] = useState('');
    const [adult, setAdult] = useState(0);

    const [price_adult_single_increment, setPrice_adult_single_increment] = useState('');
    const [adult_single, setAdult_single] = useState(0);

    const [price_child_with_bed, setPrice_child_with_bed] = useState('');
    const [child_with_bed, setChild_with_bed] = useState(0);

    const [price_child_no_bed, setPrice_child_no_bed] = useState('');
    const [child_no_bed, setChild_no_bed] = useState(0);
    
    const [amounttotal, setAmounttotal] = useState('');
    const [session_id, setSession_id] = useState('');
    const [order_id, setOrder_id] = useState('');


    const [activeTab, setActiveTab] = useState(0); // ค่าเริ่มต้นเป็น 0 (แท็บแรก)

    const [openIndex, setOpenIndex] = useState(null); 
    

    const [adultCouple, setAdultCouple] = useState(0);
    const [adultSingle, setAdultSingle] = useState(0);
    const [childWithBed, setChildWithBed] = useState(0);
    const [childNoBed, setChildNoBed] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [periodId, setPeriodId] = useState('');

    const [phone, setPhone] = useState('');
    const [departureDate, setDepartureDate] = useState('');
    const [returnDate, setReturnDate] = useState('');
    const [priceAdult, setPriceAdult] = useState(0);
    const [priceAdultSingleIncrement, setPriceAdultSingleIncrement] = useState(0);
    const [priceChildWithBed, setPriceChildWithBed] = useState(0);
    const [priceChildNoBed, setPriceChildNoBed] = useState(0);
    const [sessionId, setSessionId] = useState('');
    const [orderId, setOrderId] = useState('');
    const [step, setStep] = useState(1);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const { user } = useContext(UserContext);
    const [otpSent, setOtpSent] = useState(false);

    const parser = new DOMParser();
    const doc = parser.parseFromString(landingData?.highlight, 'text/html');
    const highlightTextRaw = doc.body.textContent.replace(/<p>|<\/p>/g, '');
    const highlightText = highlightTextRaw.replace(/DAY:/, '').split('DAY').map((line) => ` ${line}`).join('\n');
    const [isExpanded, setIsExpanded] = useState(false); 
  // State variables for each category count
  const [adultCoupleCount, setAdultCoupleCount] = useState(0);
  const [adultSingleCount, setAdultSingleCount] = useState(0);
  const [childWithBedCount, setChildWithBedCount] = useState(0);
  const [childNoBedCount, setChildNoBedCount] = useState(0);
  const [count, setCount] = useState(0);
    // Define selectedDate and setSelectedDate to fix the no-undef errors
    const [selectedDate, setSelectedDate] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  

  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
      const emailValue = e.target.value;
      setEmail(emailValue);
      setIsEmailValid(validateEmail(emailValue));
  };

  
// Function to convert Thai date format and return only the day part as a number
const getDayFromThaiDate = (thaiDateString) => {
  const [day] = thaiDateString.split(' '); // Extract only the day part
  return day; // Return the day as a string or parse as a number if needed
};


const [activeSection, setActiveSection] = useState('');

// Function to scroll to a section when a button is clicked
const scrollToSection = (sectionId) => {
  document.getElementById(sectionId)?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
};

useEffect(() => {
  const sectionIds = ['tour-list', 'contact', 'conditions', 'conditions2', 'conditions3'];
  const observerOptions = { threshold: 0.6 };

  // Intersection Observer callback to set active section
  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setActiveSection(entry.target.id);
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  // Observe each section
  sectionIds.forEach((id) => {
    const sectionElement = document.getElementById(id);
    if (sectionElement) observer.observe(sectionElement);
  });

  return () => observer.disconnect(); // Cleanup observer on unmount
}, []);


// Define periods and groupedPeriods at the top
const periods = programDetail?.periods || [];

// Group periods by month
const groupedPeriods = periods.reduce((acc, period) => {
    const month = new Date(period.departuredate).toLocaleString('th-TH', { month: 'long' });
    if (!acc[month]) {
        acc[month] = [];
    }
    acc[month].push(period);
    return acc;
}, {});

const months = Object.keys(groupedPeriods);

// Set initial active tab and selected date on mount
useEffect(() => {
    if (months.length > 0 && groupedPeriods[months[0]] && groupedPeriods[months[0]].length > 0) {
        setActiveTab(0); // Set the first month as active tab
        setSelectedDate((prev) => prev || groupedPeriods[months[0]][0].period_start_date); // Set the first date only if no date is selected
        setOpenIndex(0); // Expand the first period detail
        
    }
}, []);

// Ensure selected date is set only when it is missing or when switching tabs
useEffect(() => {
    if (months[activeTab] && groupedPeriods[months[activeTab]]?.length > 0) {
        const currentMonthPeriods = groupedPeriods[months[activeTab]];
        setSelectedDate((prev) => prev || currentMonthPeriods[0].period_start_date); // Set only if no date is selected
        setOpenIndex(0); // Open the first item by default

        
    }
}, [activeTab, months, groupedPeriods]);

// Reset counts whenever the activeTab changes
useEffect(() => {
    setAdultCoupleCount(0);
    setAdultSingleCount(0);
    setChildWithBedCount(0);
    setChildNoBedCount(0);
}, [activeTab]);

// Reset openIndex whenever selectedDate changes
useEffect(() => {
    setOpenIndex(0);
}, [selectedDate]);

// Handler for changing the active tab without resetting selectedDate unnecessarily
const handleTabClick = (index) => {
    setActiveTab(index); // Update the active tab
    const currentMonthPeriods = groupedPeriods[months[index]];
    if (currentMonthPeriods && currentMonthPeriods.length > 0) {
        // Set selected date only if the current selected date is not in the new tab
        setSelectedDate((prev) => 
            currentMonthPeriods.some((period) => period.period_start_date === prev) 
            ? prev 
            : currentMonthPeriods[0].period_start_date
        );
    }
};

// Handler for selecting a specific date
const handleDateClick = (date) => {
    setSelectedDate(date);
};

  const toggleModal = (period) => {
    setIsOpen(!isOpen);

        // Set selected period and reset pricing and count fields
        setSelectedPeriod(period);
        setTotalPrice(0);
        setAdultCouple(adultCoupleCount);
        setAdultSingle(adultSingleCount);
        setChildWithBed(childWithBedCount);
        setChildNoBed(childNoBedCount);
        
        // Clear form fields
        setName('');
        setMobile('');
        setEmail('');
        setManufacturer_ref_id(0);
        setCountry('');
        setProgram_title('');
        setProduct_code('');
        setDeparturedate('');
        setReturndate('');
      
        setPrice_adult('');
        setAdult(0);
      
        setPrice_adult_single_increment('');
        setAdult_single(0);
      
        setPrice_child_with_bed('');
        setChild_with_bed(0);
      
        setPrice_child_no_bed('');
        setChild_no_bed(0);
      
        setAmounttotal('');
        setSession_id('');
        setOrder_id('');
        setMessage('');
        setLineId('');
        // Reset step to 1
        setStep(1);
  };

      // ใช้ useEffect เพื่อติดตามการเปลี่ยนแปลงของ adultCoupleCount
      useEffect(() => {
        setAdultCouple(adultCoupleCount);
    }, [adultCoupleCount]);

    
  const handleAccordionOpen = (index) => {
    setOpenIndex(index);

    // Reset counts when a new accordion section is opened
    setAdultCoupleCount(0);
    setAdultSingleCount(0);
    setChildWithBedCount(0);
    setChildNoBedCount(0);
  };
  

  const prices = {
    adultCouple: (Number(selectedPeriod?.price_adult) || 0) - (Number(selectedPeriod?.discount) || 0),
    adultSingle: (Number(selectedPeriod?.price_adult) || 0) + (Number(selectedPeriod?.price_adult_single_increment) || 0) - (Number(selectedPeriod?.discount) || 0),
    childWithBed: (Number(selectedPeriod?.price_child_with_bed) || 0) - (Number(selectedPeriod?.discount) || 0),
    childNoBed: (Number(selectedPeriod?.price_child_no_bed) || 0) - (Number(selectedPeriod?.discount) || 0),
  };
  


  // Update total price whenever any count changes
  useEffect(() => {
    const newTotalPrice = 
      (adultCoupleCount * prices.adultCouple) +
      (adultSingleCount * prices.adultSingle) +
      (childWithBedCount * prices.childWithBed) +
      (childNoBedCount * prices.childNoBed);

    setTotalPrice(newTotalPrice);
  }, [adultCoupleCount, adultSingleCount, childWithBedCount, childNoBedCount]);


  // Handlers for increment and decrement
  const incrementCount = (setCount, count, step = 1) => {
    setCount(count + step);
  };

  const decrementCount = (setCount, count, step = 1) => {
    if (count > 0) {
      setCount(count - step);
    }
  };

        // Handler for incrementing the count
        const incrementCount2 = () => {
          setCount(count + 2);
        };
      
        // Handler for decrementing the count (with a minimum limit)
        const decrementCount2 = () => {
          setCount(Math.max(0, count - 2)); // Ensures count doesn’t go below zero
        };

    useEffect(() => {
      // เมื่อเปลี่ยน tab ให้ตั้งค่า openIndex เป็นรายการแรกใน tab ใหม่
      setOpenIndex(0);
    }, [activeTab]);


    const hasInput = () => {
      return (
        adultCouple > 0 ||
        adultSingle > 0 ||
        childWithBed > 0 ||
        childNoBed > 0
      );
    };

    const handleSendOtp = () => {
      // Add your logic to send the OTP here.
      // For example, making an API call to your backend service.
      console.log(`Sending OTP to ${mobile}`);
      setOtpSent(true); // Set state to indicate OTP has been sent.
    };

    // Function to format date and time as a continuous number
    const formatDateTimeAsNumber = (date) => {
      const day = String(date.getDate()).padStart(2, '0');     // Get day (01-31)
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (01-12)
      const year = String(date.getFullYear()).slice(2);        // Get last two digits of the year (e.g., 2024 -> 24)
      const hours = String(date.getHours()).padStart(2, '0');  // Get hours (00-23)
      const minutes = String(date.getMinutes()).padStart(2, '0'); // Get minutes (00-59)
  
      // Concatenate date and time as a continuous number
      return `${day}${month}${year}${hours}${minutes}`;
    };



  const handleSubmit1 = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    
    try {
      if (selectedPeriod) {
        const data = {
          manufacturer_ref_id,
          name: name || user?.displayName || '',
          email,
          mobile,
          country,
          program_title: programDetail.name,
          product_code: programDetail.model,
          departuredate: convertThaiDateToISO(selectedPeriod.period_start_date),
          returndate: convertThaiDateToISO(selectedPeriod.period_end_date),
          price_adult: priceAdult,
          adult: adultCouple,
          price_adult_single_increment: priceAdultSingleIncrement,
          adult_single: adultSingle,
          price_child_with_bed: priceChildWithBed,
          child_with_bed: childWithBed,
          price_child_no_bed: priceChildNoBed,
          child_no_bed: childNoBed,
          amounttotal: totalPrice,
          session_id,
          order_id: formatDateTimeAsNumber(currentDateTime),
          message,
          lineId,
        };

        console.log('Data to be sent to API:', JSON.stringify(data));

        const apiResponse = await fetch('/api/submit-form', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (!apiResponse.ok) {
          const errorDetails = await apiResponse.text();
          throw new Error(`Network response was not ok: ${errorDetails}`);
        }

        const apiResult = await apiResponse.json();
        console.log('API Response:', apiResult);

        if (apiResult.message === 'Form submitted successfully!') {
          console.log('Form submitted successfully!');
          
          // Close the modal
          setIsOpen(!isOpen);

          // Show success SweetAlert
          Swal.fire({
            icon: 'success',
            title: 'สั่งจองสำเร็จ',
            text: 'โปรดรอเจ้าหน้าที่ดำเนินการเช็คที่ว่างแล้วจะติดต่อกลับอย่างเร็วที่สุด',
            confirmButtonText: 'OK',
            customClass: {
              popup: 'bg-base-100 shadow-lg rounded-lg border border-blue',
              title: 'text-xl font-semibold text-blue',
              icon: 'text-green-500',
              confirmButton: 'btn btn-blue',
            },
          });
        } else {
          throw new Error('Received invalid data from API response.');
        }
      } else {
        console.error('selectedPeriod is null or undefined');
      }
    } catch (error) {
      console.error('Error during submission:', error);
      
      // Show error SweetAlert
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `There was an issue: ${error.message}`,
        confirmButtonText: 'OK'
      });
    } finally {
      setIsLoading(false); // End loading
    }
  };
    




    
const convertThaiDateToISO = (dateString) => {
  const thaiMonthMap = {
    'ม.ค.': '01',
    'ก.พ.': '02',
    'มี.ค.': '03',
    'เม.ย.': '04',
    'พ.ค.': '05',
    'มิ.ย.': '06',
    'ก.ค.': '07',
    'ส.ค.': '08',
    'ก.ย.': '09',
    'ต.ค.': '10',
    'พ.ย.': '11',
    'ธ.ค.': '12',
  };
  
  const dateParts = dateString.split(' ');
  const day = dateParts[0];
  const month = thaiMonthMap[dateParts[1]];
  const year = parseInt(dateParts[2]) + 2500-543; // เพิ่ม 543 เพื่อแปลงเป็น ค.ศ.
  const isoYear = year.toString().padStart(4, '0'); // แปลงเป็น 4 หลัก
  
  const isoDate = `${isoYear}-${month}-${day}`;
  
  return isoDate;
};

useEffect(() => {
  if (selectedPeriod) {
      const calculatedPriceAdult = (Number(selectedPeriod.price_adult) || 0) - (Number(selectedPeriod.discount) || 0);
      setPriceAdult(calculatedPriceAdult);
  }
}, [selectedPeriod]);

useEffect(() => {
  if (selectedPeriod) {
      const calculatedPriceAdultSingleIncrement = 
          (Number(selectedPeriod.price_adult) || 0) + 
          (Number(selectedPeriod.price_adult_single_increment) || 0) - 
          (Number(selectedPeriod.discount) || 0);
  
      setPriceAdultSingleIncrement(calculatedPriceAdultSingleIncrement);
  }
}, [selectedPeriod]);

useEffect(() => {
  if (selectedPeriod) {
      const calculatedPriceChildWithBed = 
          (Number(selectedPeriod.price_child_with_bed) || 0) - 
          (Number(selectedPeriod.discount) || 0);
  
      setPriceChildWithBed(calculatedPriceChildWithBed);
  }
}, [selectedPeriod]);

useEffect(() => {
  if (selectedPeriod) {
      const calculatedPriceChildNoBed = 
          (Number(selectedPeriod.price_child_no_bed) || 0) - 
          (Number(selectedPeriod.discount) || 0);
  
      setPriceChildNoBed(calculatedPriceChildNoBed);
  }
}, [selectedPeriod]);

// ปรับค่าใน state เมื่อเลือกช่วงเวลา
useEffect(() => {
  if (selectedPeriod) {
      setDepartureDate(selectedPeriod.period_start_date);
      setReturnDate(selectedPeriod.period_end_date);
  }
}, [selectedPeriod]);

    useEffect(() => {
        if (selectedPeriod) {
            const calculatedPriceAdult = (Number(selectedPeriod.price_adult) || 0) - (Number(selectedPeriod.discount) || 0);
            setPriceAdult(calculatedPriceAdult);
        }
    }, [selectedPeriod]);

    useEffect(() => {
        if (selectedPeriod) {
            const calculatedPriceAdultSingleIncrement = 
                (Number(selectedPeriod.price_adult) || 0) + 
                (Number(selectedPeriod.price_adult_single_increment) || 0) - 
                (Number(selectedPeriod.discount) || 0);
        
            setPriceAdultSingleIncrement(calculatedPriceAdultSingleIncrement);
        }
    }, [selectedPeriod]);

    useEffect(() => {
        if (selectedPeriod) {
            const calculatedPriceChildWithBed = 
                (Number(selectedPeriod.price_child_with_bed) || 0) - 
                (Number(selectedPeriod.discount) || 0);
        
            setPriceChildWithBed(calculatedPriceChildWithBed);
        }
    }, [selectedPeriod]);

    useEffect(() => {
        if (selectedPeriod) {
            const calculatedPriceChildNoBed = 
                (Number(selectedPeriod.price_child_no_bed) || 0) - 
                (Number(selectedPeriod.discount) || 0);
        
            setPriceChildNoBed(calculatedPriceChildNoBed);
        }
    }, [selectedPeriod]);

    // ปรับค่าใน state เมื่อเลือกช่วงเวลา
    useEffect(() => {
        if (selectedPeriod) {
            setDepartureDate(selectedPeriod.period_start_date);
            setReturnDate(selectedPeriod.period_end_date);
        }
    }, [selectedPeriod]);

    const handleNextStep = () => {
        if (step < 3) setStep(step + 1);
    };

    const handlePreviousStep = () => {
        if (step > 1) setStep(step - 1);
    };

    useEffect(() => {
      // Set the first date as active whenever activeTab changes or on component mount
      const currentMonthPeriods = groupedPeriods[months[activeTab]];
      if (currentMonthPeriods && currentMonthPeriods.length > 0) {
        setSelectedDate(currentMonthPeriods[0].period_start_date);
      }
    }, [activeTab]);
  

    const openModal = (period) => {
      // Set selected period and reset pricing and count fields
      setSelectedPeriod(period);
      setTotalPrice(0);
      setAdultCouple(0);
      setAdultSingle(0);
      setChildWithBed(0);
      setChildNoBed(0);
      
      // Clear form fields
      setName('');
      setMobile('');
      setEmail('');
      setManufacturer_ref_id(0);
      setCountry('');
      setProgram_title('');
      setProduct_code('');
      setDeparturedate('');
      setReturndate('');
    
      setPrice_adult('');
      setAdult(0);
    
      setPrice_adult_single_increment('');
      setAdult_single(0);
    
      setPrice_child_with_bed('');
      setChild_with_bed(0);
    
      setPrice_child_no_bed('');
      setChild_no_bed(0);
    
      setAmounttotal('');
      setSession_id('');
      setOrder_id('');
      setMessage('');
      setLineId('');
      // Reset step to 1
      setStep(1);
    
      // Open the modal
      document.getElementById('my_modal_2').showModal();
    };
    
  
        // Scroll behavior for navbar visibility
        useEffect(() => {
          const handleScroll = () => {
              const currentScroll = window.pageYOffset;
              if (currentScroll <= 0) {
                  setNavbarVisible(true);
                  return;
              }
  
              if (currentScroll > lastScroll.current) {
                  setNavbarVisible(false);
              } else {
                  setNavbarVisible(true);
              }
  
              lastScroll.current = currentScroll;
          };
  
          window.addEventListener('scroll', handleScroll);
          return () => {
              window.removeEventListener('scroll', handleScroll);
          };
      }, []);



    const formatCurrency = (value) => {
        if (isNaN(value)) return '0';
        return value.toLocaleString('th-TH', {
            style: 'currency',
            currency: 'THB',
            minimumFractionDigits: 0,
        });
    };

    const calculateTotalPrice = () => {
        if (!selectedPeriod) return;

        const adultCouplePrice = (Number(selectedPeriod?.price_adult) || 0) - (Number(selectedPeriod?.discount) || 0);
        const adultSinglePrice = (Number(selectedPeriod?.price_adult) || 0) + (Number(selectedPeriod?.price_adult_single_increment) || 0) - (Number(selectedPeriod?.discount) || 0);
        const childWithBedPrice = (Number(selectedPeriod?.price_child_with_bed) || 0) - (Number(selectedPeriod?.discount) || 0);
        const childNoBedPrice = (Number(selectedPeriod?.price_child_no_bed) || 0) - (Number(selectedPeriod?.discount) || 0);

        const total = (adultCouple * adultCouplePrice) + (adultSingle * adultSinglePrice) + (childWithBed * childWithBedPrice) + (childNoBed * childNoBedPrice);
        setTotalPrice(total);
    };

    useEffect(() => {
        calculateTotalPrice();
    }, [adultCouple, adultSingle, childWithBed, childNoBed, selectedPeriod]);

    const handleAdultCoupleChange = (value) => setAdultCouple(Number(value));
    const handleAdultSingleChange = (value) => setAdultSingle(Number(value));
    const handleChildWithBedChange = (value) => setChildWithBed(Number(value));
    const handleChildNoBedChange = (value) => setChildNoBed(Number(value));

    const getTourData = (landing) => {
        const tourDataMapping = {
            japan: { title: 'ทัวร์ญี่ปุ่น', apiEndpoint: 'japan' },
            china: { title: 'ทัวร์จีน', apiEndpoint: 'china' },
            vietnam: { title: 'ทัวร์เวียดนาม', apiEndpoint: 'vietnam' },
            taiwan: { title: 'ทัวร์ไต้หวัน', apiEndpoint: 'taiwan ' },
            korea: { title: 'ทัวร์เกาหลี', apiEndpoint: 'korea' },
            singapore: { title: 'ทัวร์สิงคโปร์', apiEndpoint: 'singapore' },
            hongkong: { title: 'ทัวร์ฮ่องกง', apiEndpoint: 'hongkong' },
            macao: { title: 'ทัวร์มาเก๊า', apiEndpoint: 'macao' },
            malaysia: { title: 'ทัวร์มาเลเซีย', apiEndpoint: 'malaysia' },
            guangzhou: { title: 'ทัวร์กวางเจาเทรดแฟร์', apiEndpoint: 'guangzhou' },
            india: { title: 'ทัวร์อินเดีย', apiEndpoint: 'india' },
            myanmar: { title: 'ทัวร์พม่า', apiEndpoint: 'myanmar' },
            laos: { title: 'ทัวร์ลาว', apiEndpoint: 'laos' },
            bhutan: { title: 'ทัวร์ภูฏาน', apiEndpoint: 'bhutan' },
            bali: { title: 'ทัวร์บาหลี', apiEndpoint: 'bali' },
            dubai: { title: 'ทัวร์ดูไบ', apiEndpoint: 'dubai' },
            jordan: { title: 'ทัวร์จอร์แดน', apiEndpoint: 'jordan' },
            egypt: { title: 'ทัวร์อียิปต์', apiEndpoint: 'egypt' },
        };
        
        return tourDataMapping[landing] || {};
    };

    const { title } = getTourData(landing);

    useEffect(() => {
        const fetchProgramDetail = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch(`/api/thaifly/program/${model}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setProgramDetail(data.product);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProgramDetail();
    }, [model]);



    const formatPrice = (price) => {
        return new Intl.NumberFormat('th-TH', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(price || 0);
    };





    if (loading) {
      return (
          <div>
            <Suspense fallback={<div>Loading...</div>}>
                <Loading />
            </Suspense>
          </div>
      );
  }
    return (
        <>


        
            <Helmet>
                <title>{programDetail.name || 'รายละเอียดทัวร์'}</title>
                <meta name="description" content={programDetail.meta_description} />
              <meta name="keywords" content="ทัวร์, ญี่ปุ่น" />
            </Helmet>


            <div className="">
                <div className={`${navbarVisible ? 'block' : 'hidden'} border-b border-gray-300 fixed top-0 left-0 right-0 z-50`}>
                <Suspense fallback={<div>Loading Navbar...</div>}>
                    <Navbar />
                </Suspense>
                </div>

                <nav className={`w-full fixed top-0 bg-white z-40 border-b border-gray-300 ${navbarVisible ? 'top-[64px] md:top-[74px]' : 'top-0'}`}>
                <div className="absolute right-2 top-0 h-full w-20 bg-gradient-to-l from-white to-transparent pointer-events-none"></div>


    <div className="max-w-full px-4 py-3 mx-auto">
        <div className="flex items-center overflow-x-auto scrollbar-hide"> {/* Add overflow-x-auto to enable scrolling on mobile */}
            <ul className="flex flex-row font-medium mt-0 space-x-8 rtl:space-x-reverse text-sm whitespace-nowrap">
            <li>
  <button onClick={() => scrollToSection('tour-list')} className="flex items-center text-gray-900 hover:underline" aria-current="page">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ticket-perforated mr-2" viewBox="0 0 16 16">
      <path d="M4 4.85v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9z"/>
      <path d="M1.5 3A1.5 1.5 0 0 0 0 4.5V6a.5.5 0 0 0 .5.5 1.5 1.5 0 1 1 0 3 .5.5 0 0 0-.5.5v1.5A1.5 1.5 0 0 0 1.5 13h13a1.5 1.5 0 0 0 1.5-1.5V10a.5.5 0 0 0-.5-.5 1.5 1.5 0 0 1 0-3A.5.5 0 0 0 16 6V4.5A1.5 1.5 0 0 0 14.5 3zM1 4.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1.05a2.5 2.5 0 0 0 0 4.9v1.05a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1.05a2.5 2.5 0 0 0 0-4.9z"/>
    </svg>
    รายการทัวร์
  </button>
</li>

                <li>
                <button onClick={() => scrollToSection('contact')} className="flex items-center text-gray-900 hover:underline" aria-current="page">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-telephone-outbound mr-2" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5"/>
</svg>
                  
                  ติดต่อเรา</button>
                </li>
                <li>
                <button onClick={() => scrollToSection('conditions')} className="flex items-center text-gray-900 hover:underline" aria-current="page">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle mr-2" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"/>
</svg>
                      เงื่อนไขการจองทัวร์</button>
                </li>
                <li>
                <button onClick={() => scrollToSection('conditions2')} className="flex items-center text-gray-900 hover:underline" aria-current="page">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle mr-2" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>
                      
ข้อตกลงการซื้อแพคเกจทัวร์</button>
                </li>
                <li>
                <button onClick={() => scrollToSection('conditions3')} className="flex items-center text-gray-900 hover:underline" aria-current="page">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check mr-2" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"/>
  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"/>
</svg>
                      
                      เอกสารที่ต้องเตรียม</button>
                </li>
            </ul>
        </div>
    </div>
</nav>





            </div>
                  


 <div className="relative max-w-full mx-auto rounded-b-[45px] border-gray-200 mt-4 mb-2 overflow-hidden">

  
  {/* Blurred Background Layer */}
  <div
  className="inset-0 shadow bg-white opacity-20 absolute bg-cover bg-center -z-10"
  style={{
    backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 80%), url(${programDetail.image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'blur(10px)',
  }}
  loading="lazy" 
></div>


  {/* Overlay for content */}
  <div className="container mx-auto p-4 rounded-lg border-gray-200 mt-8 mb-2 ">

  <nav className="w-full rounded-md mt-16">
                        <ol className="list -reset flex items-center space-x-2 text-sm">
                            <li>
                                <Link to="/" className="text-blue-600 transition duration-150 ease-in-out hover:text-blue-800">หน้าหลัก</Link>
                            </li>
                            <span className="text-gray-400">/</span>
                            <li>
                                <Link to={`/search`} className="text-blue-600 transition duration-150 ease-in-out hover:text-blue-800">
                                ค้นหา</Link>
                            </li>
                            <span className="text-gray-400">/</span>
                            <li className="text-gray-400">{programDetail.model}</li>
                        </ol>
 </nav>


                        {/* Program Image and Details */}
                        <div className="lg:flex lg:items-center mt-2">
                            <div className="lg:w-1/3 flex justify-center lg:justify-end">
                                <img
                                    src={programDetail.image}
                                    alt={programDetail.name}
                                    loading="lazy" 
                                    className="rounded-lg w-full h-auto object-cover lg:max-w-l"
                                />
                            </div>

                            <div className="w-full lg:w-2/3 lg:pl-8 lg:mt-0">
                                <h1 className="text-2xl sm:text-3xl md:text-3xl text-gray-800 mt-2 mb-2">
                                    {programDetail.name}
                                </h1>


                                
                                <p className="text-gray-700 mb-4">{programDetail.meta_description}</p>

                                {/* Meta Information */}
                                <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 text-center mb-4">
                                    <div>
                                        <div className="text-sm font-bold text-gray-800">รหัสทัวร์</div>
                                        <div className="text-blue-600 mt-1">{programDetail.model}</div>
                                    </div>
                                    <div>
                                        <div className="text-sm font-bold text-gray-800">จำนวนวัน</div>
                                        <div className="text-blue-600 mt-1">
                                            {programDetail.day} วัน | {programDetail.night} คืน
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-sm font-bold text-gray-800">สายการบิน</div>
                                        <div className="flex justify-center mt-1">
                                            <img
                                                src={programDetail.airline_icon}
                                                alt={programDetail.airline}
                                                width="80"
                                                height="45"
                                                className="rounded-lg mt-1"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-sm font-bold text-gray-800">ราคาเริ่มต้น</div>
                                        <div className="text-red-600 text-2xl font-bold mt-1">
                                            ฿ {formatPrice(programDetail.price)}
                                        </div>
                                    </div>




                                </div>

                                                                          {/* Action Buttons */}
      <div className="flex flex-col gap-3">
  <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 w-full">
    <button
      onClick={() => scrollToSection('tour-list')}
      type="button"
      className="flex items-center justify-start sm:justify-center text-gray-800 bg-white border border-gray-300 hover:border-gray-500 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-left sm:text-center"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-2" viewBox="0 0 16 16">
        <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6zm4 1a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5m0 5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5M4 8a1 1 0 0 0 1 1h6a1 1 0 1 0 0-2H5a1 1 0 0 0-1 1"/>
      </svg>
      จองผ่านทางเว็บไซต์
    </button>

    <button
      type="button"
      className="flex items-center justify-start sm:justify-center text-gray-800 bg-white border border-gray-300 hover:border-gray-500 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-left sm:text-center"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-2" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5"/>
      </svg>
      โทรจองทางโทรศัพท์
    </button>

    <button
      type="button"
      className="flex items-center justify-start sm:justify-center text-gray-800 bg-white border border-gray-300 hover:border-gray-500 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-left sm:text-center"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-2" viewBox="0 0 16 16">
        <path d="M8 0c4.411 0 8 2.912 8 6.492 0 1.433-.555 2.723-1.715 3.994-1.678 1.932-5.431 4.285-6.285 4.645-.83.35-.734-.197-.696-.413l.003-.018.114-.685c.027-.204.055-.521-.026-.723-.09-.223-.444-.339-.704-.395C2.846 12.39 0 9.701 0 6.492 0 2.912 3.59 0 8 0M5.022 7.686H3.497V4.918a.156.156 0 0 0-.155-.156H2.78a.156.156 0 0 0-.156.156v3.486c0 .041.017.08.044.107v.001l.002.002.002.002a.15.15 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157m.791-2.924a.156.156 0 0 0-.156.156v3.486c0 .086.07.155.156.155h.562c.086 0 .155-.07.155-.155V4.918a.156.156 0 0 0-.155-.156zm3.863 0a.156.156 0 0 0-.156.156v2.07L7.923 4.832l-.013-.015v-.001l-.01-.01-.003-.003-.011-.009h-.001L7.88 4.79l-.003-.002-.005-.003-.008-.005h-.002l-.003-.002-.01-.004-.004-.002-.01-.003h-.002l-.003-.001-.009-.002h-.006l-.003-.001h-.004l-.002-.001h-.574a.156.156 0 0 0-.156.155v3.486c0 .086.07.155.156.155h.56c.087 0 .157-.07.157-.155v-2.07l1.6 2.16a.2.2 0 0 0 .039.038l.001.001.01.006.004.002.008.004.007.003.005.002.01.003h.003a.2.2 0 0 0 .04.006h.56c.087 0 .157-.07.157-.155V4.918a.156.156 0 0 0-.156-.156zm3.815.717v-.56a.156.156 0 0 0-.155-.157h-2.242a.16.16 0 0 0-.108.044h-.001l-.001.002-.002.003a.16.16 0 0 0-.044.107v3.486c0 .041.017.08.044.107l.002.003.002.002a.16.16 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156V6.443c0-.086-.07-.155-.155-.155h-.562a.155.155 0 0 0-.155.155z"/>
      </svg>
      สอบถามข้อมูลเพิ่มเติม
    </button>
  </div>

  <a
    href={programDetail.pdf}
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center justify-start sm:justify-center text-white bg-blue-600 border border-gray-300 hover:border-gray-500 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 w-full mt-2 text-left sm:text-center"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-2" viewBox="0 0 16 16">
      <path d="M5.523 12.424q.21-.124.459-.238a8 8 0 0 1-.45.606c-.28.337-.498.516-.635.572l-.035.012a.3.3 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548m2.455-1.647q-.178.037-.356.078a21 21 0 0 0 .5-1.05 12 12 0 0 0 .51.858q-.326.048-.654.114m2.525.939a4 4 0 0 1-.435-.41q.344.007.612.054c.317.057.466.147.518.209a.1.1 0 0 1 .026.064.44.44 0 0 1-.06.2.3.3 0 0 1-.094.124.1.1 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256M8.278 6.97c-.04.244-.108.524-.2.829a5 5 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.5.5 0 0 1 .145-.04c.013.03.028.092.032.198q.008.183-.038.465z"/>
      <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.7 11.7 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.86.86 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.84.84 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.8 5.8 0 0 0-1.335-.05 11 11 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.24 1.24 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a20 20 0 0 1-1.062 2.227 7.7 7.7 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103"/>
    </svg>
    รายละเอียดโปรแกรม PDF
  </a>
</div>

                            </div>
                        </div>
                    </div>
    
</div>



<div className="container mx-auto mb">

<section id="tour-list">
<div className="px-6 pt-10">

                    <h1 className="font-bold mt-2 mb-2 text-2xl flex items-center text-blue-500">
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-ticket-perforated mr-2" viewBox="0 0 16 16">
    <path d="M4 4.85v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9z"/>
    <path d="M1.5 3A1.5 1.5 0 0 0 0 4.5V6a.5.5 0 0 0 .5.5 1.5 1.5 0 1 1 0 3 .5.5 0 0 0-.5.5v1.5A1.5 1.5 0 0 0 1.5 13h13a1.5 1.5 0 0 0 1.5-1.5V10a.5.5 0 0 0-.5-.5 1.5 1.5 0 0 1 0-3A.5.5 0 0 0 16 6V4.5A1.5 1.5 0 0 0 14.5 3zM1 4.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1.05a2.5 2.5 0 0 0 0 4.9v1.05a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1.05a2.5 2.5 0 0 0 0-4.9z"/>
  </svg>
  รายการทัวร์
</h1>

                    {/* Tabs for Periods */}

<div className="package-options py-4">

        {/* Month Tabs */}
        <div className="relative">
  <div className="absolute right-0 top-0 h-full w-10 bg-gradient-to-l from-white to-transparent pointer-events-none"></div> {/* Gradient overlay */}
  <div role="tablist" className="flex justify-start items-center gap-1 overflow-x-auto px-2 py-1">
    {months.map((month, index) => {
      const periodsForMonth = groupedPeriods[month];
      let yearBE = '';
      if (periodsForMonth && periodsForMonth.length > 0) {
        const firstPeriodDate = periodsForMonth[0].period_start_date;
        const yearShort = firstPeriodDate.split(' ')[2];
        yearBE = `25${yearShort}`;
      }

      return (
        <button
          key={index}
          onClick={() => setActiveTab(index)}
          className={`tab-item ${activeTab === index ? 'tab-item-active' : ''} text-sm px-3 py-1`}
          role="tab"
        >
          <div className="text-center">
            <p className="whitespace-nowrap">{month} {yearBE}</p>
          </div>
        </button>
      );
    })}
  </div>
</div>



{/* Date Buttons */}
<div className="grid grid-cols-3 gap-3 px-2 mt-2 py-1 overflow-x-auto scrollbar-hide sm:grid-cols-4 md:flex md:flex-row md:gap-3 md:max-w-full md:justify-start mx-auto">
    {groupedPeriods[months[activeTab]]?.map((period, index) => {
        const isActive = selectedDate === period.period_start_date;

        return (
            <button
                key={index}
                className={`flex-none flex flex-col items-center justify-center border rounded-lg shadow-sm ${
                    isActive ? 'border-blue-500 text-blue-500' : 'border-gray-300 text-gray-400'
                }`}
                onClick={() => handleDateClick(period.period_start_date)}
                style={{ width: '80px', height: '96px' }} // Fixed width and height
            >
                <span className={`${isActive ? "text-blue-500" : "text-gray-400"} text-sm`}>
                    {period.period_start_date.split(' ')[1]} {/* Month */}
                </span>
                <span className={`${isActive ? "text-blue-500 font-semibold" : "text-gray-400 font-semibold"} text-2xl`}>
                    {period.period_start_date.split(' ')[0]} {/* Day */}
                </span>
                <span className={`${isActive ? "text-blue-500" : "text-gray-400"} text-sm`}>
                    25{period.period_start_date.split(' ')[2]} {/* Year */}
                </span>
            </button>
        );
    })}
</div>










{groupedPeriods[months[activeTab]] && groupedPeriods[months[activeTab]].length > 0 ? (
  <div className="mt-4">
    {groupedPeriods[months[activeTab]]
      .filter(period => period.period_start_date === selectedDate)
      .map((period, periodIndex) => (
        <div
          key={`${selectedDate}-${periodIndex}`}
          className={`collapse collapse-plus border-2 rounded-lg mb-2 ${openIndex === periodIndex ? 'border-blue-500' : 'border-gray-200'}`}
        >
          <input
            type="radio"
            name={`accordion-${selectedDate}`}
            defaultChecked={periodIndex === openIndex} // Set checked based on openIndex
            onChange={() => handleAccordionOpen(periodIndex)}
          />
          <div className="collapse-title text-l mb:text-xs font-medium flex justify-between items-center">
            <span>{period.period_start_date} - {period.period_end_date}</span>
          </div>
          <div className="collapse-content">
            <div className="border-b mb-2"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              
              {/* Calendar Component */}
              <div className="rounded-lg">
              
                <CalendarComponent
                  startDate={convertThaiDateToISO(period.period_start_date)}
                  endDate={convertThaiDateToISO(period.period_end_date)}
                />
              
              </div>

              {/* Tour Details and Pricing Options */}
              <div className="rounded-lg">
                <h1 className="text-l text-blue-600 mb-2">{programDetail.name}</h1>
                <div>
  <h3 className="text-sm font-semibold">รายละเอียด</h3>
  <p className={`${isExpanded ? '' : 'line-clamp-1'}`}>
    {programDetail.meta_description}
  </p>

  {/* Grid showing additional details */}
  <div className={`grid grid-cols-2 lg:grid-cols-3 gap-3 mt-2 ${isExpanded ? '' : 'hidden'}`}>
    {/* Tour Code */}
    <div className="stat">
      <div className="stat-title text-black font-bold text-sm">รหัสทัวร์</div>
      <div className="block mt-1 text-blue-600">{programDetail.model || 'ไม่ระบุ'}</div>
    </div>

    {/* Number of Days */}
    <div className="stat">
      <div className="stat-title text-black font-bold text-sm">จำนวนวัน</div>
      <div className="block mt-1 text-blue-600">
        {programDetail.day ? `${programDetail.day} วัน` : 'ไม่ระบุ'} | {programDetail.night ? `${programDetail.night} คืน` : 'ไม่ระบุ'}
      </div>
    </div>

    {/* Airline Information */}
    <div className="stat">
      <div className="stat-title text-black font-bold text-sm">สายการบิน</div>
      <div className="stat-value mt-1">
        {programDetail.airline_icon ? (
          <img
            width="80"
            height="45"
            className="rounded-xl"
            src={programDetail.airline_icon}
            alt={programDetail.airline || 'Airline Icon'}
          />
        ) : (
          <span className="text-blue-600">ไม่ระบุ</span>
        )}
      </div>
    </div>
  </div>

  {/* Toggle button to show/hide additional information */}
  <button
    onClick={() => setIsExpanded(!isExpanded)}
    className="text-blue-500 mt-2 text-sm font-medium focus:outline-none"
  >
    {isExpanded ? 'ซ่อน' : 'ดูเพิ่มเติม'}
  </button>
</div>


                {/* Pricing Options for Adult Double */}
                <div className="flex justify-between items-center mt-2">
                  <div className="w-full px-3 bg-white border border-gray-200 rounded-lg">
                    <div className="w-full flex justify-between items-center gap-x-3">
                      <div>
                        <span className="block font-medium text-sm text-gray-800">ผู้ใหญ่พักคู่</span>
                        <span className="block text-l text-gray-500">
                          ( ราคา ฿{formatPrice((Number(period.price_adult) || 0) - (Number(period.discount) || 0))})
                        </span>
                      </div>
                      <div className="flex items-center gap-x-1.5">
                        <button
                          type="button"
                          className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                          aria-label="Decrease"
                          onClick={() => decrementCount(setAdultCoupleCount, adultCoupleCount, 2)}
                        >
                          <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M5 12h14"></path>
                          </svg>
                        </button>
                        <input
                          className="p-0 w-10 bg-transparent border-0 text-gray-800 text-center align-middle focus:ring-0 text-sm"
                          type="number"
                          value={adultCoupleCount}
                          readOnly
                          aria-roledescription="Number field"
                        />
                        <button
                          type="button"
                          className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                          aria-label="Increase"
                          onClick={() => incrementCount(setAdultCoupleCount, adultCoupleCount, 2)}
                        >
                          <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M5 12h14"></path>
                            <path d="M12 5v14"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                        {/* Adult Single */}
                        <div className="flex justify-between items-center mt-2">
                          <div className="w-full px-3 bg-white border border-gray-200 rounded-lg">
                            <div className="w-full flex justify-between items-center gap-x-3">
                              <div>
                                <span className="block font-medium text-sm text-gray-800">ผู้ใหญ่พักเดี่ยว</span>
                                <span className="block text-l text-gray-500">
                                  ( ราคา ฿{formatPrice((Number(period.price_adult) || 0) + (Number(period.price_adult_single_increment) || 0) - (Number(period.discount) || 0))})
                                </span>
                              </div>
                              <div className="flex items-center gap-x-1.5">
                                <button
                                  type="button"
                                  className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                                  aria-label="Decrease"
                                  onClick={() => decrementCount(setAdultSingleCount, adultSingleCount)}
                                >
                                  <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M5 12h14"></path>
                                  </svg>
                                </button>
                                <input
                                  className="p-0 w-10 bg-transparent border-0 text-gray-800 text-center align-middle focus:ring-0 text-sm"
                                  type="number"
                                  value={adultSingleCount}
                                  readOnly
                                  aria-roledescription="Number field"
                                />
                                <button
                                  type="button"
                                  className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                                  aria-label="Increase"
                                  onClick={() => incrementCount(setAdultSingleCount, adultSingleCount)}
                                >
                                  <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M5 12h14"></path>
                                    <path d="M12 5v14"></path>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Child with Bed */}
                        <div className="flex justify-between items-center mt-2">
                          <div className="w-full px-3 bg-white border border-gray-200 rounded-lg">
                            <div className="w-full flex justify-between items-center gap-x-3">
                              <div>
                                <span className="block font-medium text-sm text-gray-800">เด็กมีเตียง</span>
                                <span className="block text-l text-gray-500">
                                  ( ราคา ฿{formatPrice((Number(period.price_child_with_bed) || 0) - (Number(period.discount) || 0))})
                                </span>
                              </div>
                              <div className="flex items-center gap-x-1.5">
                                <button
                                  type="button"
                                  className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                                  aria-label="Decrease"
                                  onClick={() => decrementCount(setChildWithBedCount, childWithBedCount)}
                                >
                                  <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M5 12h14"></path>
                                  </svg>
                                </button>
                                <input
                                  className="p-0 w-10 bg-transparent border-0 text-gray-800 text-center align-middle focus:ring-0 text-sm"
                                  type="number"
                                  value={childWithBedCount}
                                  readOnly
                                  aria-roledescription="Number field"
                                />
                                <button
                                  type="button"
                                  className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                                  aria-label="Increase"
                                  onClick={() => incrementCount(setChildWithBedCount, childWithBedCount)}
                                >
                                  <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M5 12h14"></path>
                                    <path d="M12 5v14"></path>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Child without Bed */}
                        <div className="flex justify-between items-center mt-2">
                          <div className="w-full px-3 bg-white border border-gray-200 rounded-lg">
                            <div className="w-full flex justify-between items-center gap-x-3">
                              <div>
                                <span className="block font-medium text-sm text-gray-800">เด็กไม่มีเตียง</span>
                                <span className="block text-l text-gray-500">
                                  ( ราคา ฿{formatPrice((Number(period.price_child_no_bed) || 0) - (Number(period.discount) || 0))})
                                </span>
                              </div>
                              <div className="flex items-center gap-x-1.5">
                                <button
                                  type="button"
                                  className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                                  aria-label="Decrease"
                                  onClick={() => decrementCount(setChildNoBedCount, childNoBedCount)}
                                >
                                  <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M5 12h14"></path>
                                  </svg>
                                </button>
                                <input
                                  className="p-0 w-10 bg-transparent border-0 text-gray-800 text-center align-middle focus:ring-0 text-sm"
                                  type="number"
                                  value={childNoBedCount}
                                  readOnly
                                  aria-roledescription="Number field"
                                />
                                <button
                                  type="button"
                                  className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50"
                                  aria-label="Increase"
                                  onClick={() => incrementCount(setChildNoBedCount, childNoBedCount)}
                                >
                                  <svg className="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M5 12h14"></path>
                                    <path d="M12 5v14"></path>
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                {/* Button to Check Availability */}
                <div className="mt-4">
                  <div className="card-actions">
                    <button
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-full"
                      onClick={() => toggleModal(period)}
                    >
                      เช็คที่นั่ง
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      ))}
  </div>
) : (
  <p className="text-center mt-4">ไม่มีช่วงเดินทางในเดือนนี้</p>
)}

</div>





    <form onSubmit={handleSubmit1}>
      <div id="my_modal_2" className={`hs-overlay ${isOpen ? 'size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-auto' : 'hidden pointer-events-none'} transition-opacity duration-500`} role="dialog" tabIndex="-1" aria-labelledby="hs-full-screen-label">
        <div className="flex flex-col bg-white pointer-events-auto max-w-full max-h-full h-full ">

          
  {/* Header */}

<div className="flex flex-col sm:flex-row justify-between items-center py-3 border-b">

  <div className="container mx-auto px-4 flex-shrink-0 w-full">
    <ol className="flex flex-wrap items-center justify-center w-full space-x-4 sm:space-x-8 rtl:space-x-reverse overflow-x-auto">
      
      {/* Step Indicators */}
      <li className={`flex flex-col items-center ${step >= 1 ? 'text-blue-600' : 'text-gray-500'} space-y-1 sm:space-y-0 rtl:space-y-reverse`}>
        <span className={`flex items-center justify-center w-8 h-8 sm:w-8 sm:h-8 border rounded-full shrink-0 ${step >= 1 ? 'border-blue-600 text-blue-600' : 'border-gray-500 text-gray-500'}`}>
          1
        </span>
        <span className="text-center whitespace-nowrap">
          <h3 className="font-medium leading-tight text-xs sm:text-sm">รายการจอง</h3>
          <p className="text-xs sm:text-sm">{step > 1 ? 'Completed' : 'Pending'}</p>
        </span>
      </li>

      {/* Step 2 */}
      <li className={`flex flex-col items-center ${step >= 2 ? 'text-blue-600' : 'text-gray-500'} space-y-1 sm:space-y-0 rtl:space-y-reverse`}>
        <span className={`flex items-center justify-center w-8 h-8 sm:w-8 sm:h-8 border rounded-full shrink-0 ${step >= 2 ? 'border-blue-600 text-blue-600' : 'border-gray-500 text-gray-500'}`}>
          2
        </span>
        <span className="text-center whitespace-nowrap">
          <h3 className="font-medium leading-tight text-xs sm:text-sm">กรอกข้อมูลผู้จอง</h3>
          <p className="text-xs sm:text-sm">{step > 2 ? 'Completed' : 'Pending'}</p>
        </span>
      </li>

      {/* Step 3 */}
      <li className={`flex flex-col items-center ${step === 3 ? 'text-blue-600' : 'text-gray-500'} space-y-1 sm:space-y-0 rtl:space-y-reverse`}>
        <span className={`flex items-center justify-center w-8 h-8 sm:w-8 sm:h-8 border rounded-full shrink-0 ${step === 3 ? 'border-blue-600 text-blue-600' : 'border-gray-500 text-gray-500'}`}>
          3
        </span>
        <span className="text-center whitespace-nowrap">
          <h3 className="font-medium leading-tight text-xs sm:text-sm">ยืนยันข้อมูล</h3>
          <p className="text-xs sm:text-sm">{step === 3 ? 'Completed' : 'Pending'}</p>
        </span>
      </li>
    </ol>
  </div>


</div>




          <div className="p-4 overflow-y-auto">








          {/* Content Area */}
          <div className="overflow-y-auto flex-grow">
            {selectedPeriod ? (
              step === 1 ? (
<div className="lg:w-full mt-2 mb-2 flex flex-col lg:flex-row items-center lg:items-start">
  {/* Image on the left */}
  <img 
  src={programDetail.image} 
  alt={programDetail.name} 
  className="rounded-lg w-full lg:w-auto lg:h-80 xl:h-96 mb-4 lg:mb-0 lg:mr-4"
/>


  {/* Table and Details on the right */}
  <div className="w-full">
    <div className="text-left lg:text-left text-left mb-4">
      <p className="font-bold text-l lg:text-l">{programDetail.name}</p>
      <p className="text-blue-500 text-xs lg:text-sm">
        ช่วงเวลาเดินทาง : {selectedPeriod.period_start_date} - {selectedPeriod.period_end_date}
      </p>
    </div>

    {/* Table */}
    <div className="overflow-x-auto">
      <table className="table table-xs text-center w-full">
        <thead>
          <tr>
            <th className='text-left'>ประเภท</th>
            <th>ราคา</th>
            <th>จำนวน</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='text-lg text-left'>ผู้ใหญ่พักคู่</td>
            <td className='text-lg'>
              {formatCurrency(
                (Number(selectedPeriod.price_adult) || 0) - (Number(selectedPeriod.discount) || 0)
              )}
            </td>
            <td>
              <select
                value={adultCouple}
                onChange={(e) => handleAdultCoupleChange(e.target.value)}
                className="select w-[80px] select-bordered max-w-xs"
              >
                {[...Array(12).keys()].filter(num => num % 2 === 0).map((num) => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td className='text-lg text-left'>ผู้ใหญ่พักเดี่ยว</td>
            <td className='text-lg'>
              {formatCurrency(
                (Number(selectedPeriod.price_adult) || 0) +
                (Number(selectedPeriod.price_adult_single_increment) || 0) -
                (Number(selectedPeriod.discount) || 0)
              )}
            </td>
            <td>
              <select
                value={adultSingle}
                onChange={(e) => handleAdultSingleChange(e.target.value)}
                className="select w-[80px] select-bordered max-w-xs"
              >
                {[...Array(10).keys()].map((num) => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td className='text-lg text-left'>เด็กมีเตียง</td>
            <td className='text-lg'>
              {formatCurrency(
                (Number(selectedPeriod.price_child_with_bed) || 0) - (Number(selectedPeriod.discount) || 0)
              )}
            </td>
            <td>
              <select
                value={childWithBed}
                onChange={(e) => handleChildWithBedChange(e.target.value)}
                className="select w-[80px] select-bordered max-w-xs"
              >
                {[...Array(10).keys()].map((num) => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td className='text-lg text-left'>เด็กไม่มีเตียง</td>
            <td className='text-lg'>
              {formatCurrency(
                (Number(selectedPeriod.price_child_no_bed) || 0) - (Number(selectedPeriod.discount) || 0)
              )}
            </td>
            <td>
              <select
                value={childNoBed}
                onChange={(e) => handleChildNoBedChange(e.target.value)}
                className="select w-[80px] select-bordered max-w-xs"
              >
                {[...Array(10).keys()].map((num) => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


  </div>
</div>

              ) : step === 2 ? (
<div className="mt-1 mb-4 ml-1 h-auto">
    <div className="mb-6 flex flex-col sm:flex-row sm:items-center">
        <div className="mr-2 ml-2 sm:mb-0 w-1/1 sm:w-1/2">
            <label htmlFor="name" className="block text-gray-700 font-medium">
                ชื่อ - นามสกุล*
            </label>
            <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="input input-bordered input-sm w-full"
                required
            />
            <div className="label">
                <span className="label-text-alt text-blue-500">
                    กรุณากรอก ชื่อ-นามสกุล *
                </span>
            </div>
        </div>

        <div className="mr-2 ml-2 sm:mb-0 w-1/1 sm:w-1/2">
            <label htmlFor="email" className="block text-gray-700 font-medium">
                อีเมล*
            </label>
            <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                className={`input input-bordered input-sm w-full ${isEmailValid ? '' : 'border-red-500'}`}
                required
            />
            <div className="label">
                <span className={`label-text-alt ${isEmailValid ? 'text-blue-500' : 'text-red-500'}`}>
                    {isEmailValid
                        ? 'กรุณาใช้ mail จริงเพื่อรับข้อมูลรายละเอียดทัวร์ example@mail.com *'
                        : 'กรุณากรอกอีเมลให้ถูกต้อง เช่น name@domain.com'}
                </span>
            </div>
        </div>
    </div>

    <div className="mb-2 flex flex-col sm:flex-row sm:items-start">
        <div className="mr-2 ml-2 sm:mb-0 w-1/1 sm:w-1/2">
            <label htmlFor="mobile" className="block text-gray-700 font-medium">
                เบอร์โทร*
            </label>
            <input
                type="tel"
                id="mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                className="input input-bordered input-sm w-full"
                required
            />
            <div className="label">
                <span className="label-text-alt text-blue-500">
                    กรุณาใช้เบอร์จริงสำหรับให้เจ้าหน้าที่ติดต่อกลับ *
                </span>
            </div>
        </div>

        <div className="mr-2 ml-2 sm:mb-0 w-1/1 sm:w-1/2">
            <label htmlFor="lineId" className="block text-gray-700 font-medium">
                ไอดีไลน์*
            </label>
            <input
                type="text"
                id="lineId"
                value={lineId}
                onChange={(e) => setLineId(e.target.value)}
                className="input input-bordered input-sm w-full"
                required
            />
            <div className="label">
                <span className="label-text-alt text-blue-500">
                    กรุณากรอกไอดีไลน์จริง *
                </span>
            </div>
        </div>
    </div>

    <div className="mb-6 mr-2 ml-2">
        <label className="label">
            <span className="label -text text-gray-700 font-medium">
                ข้อความแจ้งเจ้าหน้าที่ เพิ่มเติม
            </span>
        </label>
        <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="textarea textarea-bordered w-full h-24"
            placeholder="เพิ่มเติม"
        />
    </div>
</div>

              ) : step === 3 ? (
<div className="p-6 bg-white rounded-lg shadow-md">
  {/* Confirmation Header */}
  <h2 className="text-2xl font-bold mb-4 text-blue-500">ตรวจสอบข้อมูลก่อนส่ง</h2>

  {/* Information Display */}
  <div className="space-y-2">
    {/* Name and Email in one row */}
    <div className="flex flex-col sm:flex-row sm:space-x-4">
      <p className="text-gray-700 flex-1">
        <span className="font-semibold">ชื่อ:</span> {name}
      </p>
      <p className="text-gray-700 flex-1">
        <span className="font-semibold">อีเมล:</span> {email}
      </p>
    </div>
    <div className="flex flex-col sm:flex-row sm:space-x-4">
      <p className="text-gray-700 flex-1">
        <span className="font-semibold">เบอร์โทร:</span> {mobile}
      </p>
      <p className="text-gray-700 flex-1">
        <span className="font-semibold">ไอดีไลน์:</span> {lineId}
      </p>
    </div>

    <div className="overflow-x-auto">
  <table className="min-w-full table-auto">
    <thead>
      <tr className="bg-gray-100">
        <th className="px-4 py-2 text-left text-gray-600 font-semibold">ประเภท</th>
        <th className="px-4 py-2 text-left text-gray-600 font-semibold text-center">จำนวน</th>
        <th className="px-4 py-2 text-left text-gray-600 font-semibold text-right">ราคา</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="border px-4 py-2 text-gray-700">
          <span className="font-semibold">ผู้ใหญ่พักคู่</span>
        </td>
        <td className="border px-4 py-2 text-gray-700 text-center">{adultCouple} ท่าน</td>
        <td className="border px-4 py-2 text-gray-700 text-right">
          {adultCouple > 0 ? (
            formatCurrency(
              ((Number(selectedPeriod.price_adult) || 0) - (Number(selectedPeriod.discount) || 0)) * adultCouple
            )
          ) : (
            '฿0'
          )}
        </td>
      </tr>
      <tr>
        <td className="border px-4 py-2 text-gray-700">
          <span className="font-semibold">ผู้ใหญ่พักเดี่ยว</span>
        </td>
        <td className="border px-4 py-2 text-gray-700 text-center">{adultSingle} ท่าน</td>
        <td className="border px-4 py-2 text-gray-700 text-right">
          {adultSingle > 0 ? (
            formatCurrency(
              ((Number(selectedPeriod.price_adult) || 0) + (Number(selectedPeriod.price_adult_single_increment) || 0) - (Number(selectedPeriod.discount) || 0)) * adultSingle
            )
          ) : (
            '฿0'
          )}
        </td>
      </tr>
      <tr>
        <td className="border px-4 py-2 text-gray-700">
          <span className="font-semibold">เด็กมีเตียง</span>
        </td>
        <td className="border px-4 py-2 text-gray-700 text-center">{childWithBed} ท่าน</td>
        <td className="border px-4 py-2 text-gray-700 text-right">
          {childWithBed > 0 ? (
            formatCurrency(
              ((Number(selectedPeriod.price_child_with_bed) || 0) - (Number(selectedPeriod.discount) || 0)) * childWithBed
            )
          ) : (
            '฿0'
          )}
        </td>
      </tr>
      <tr>
        <td className="border px-4 py-2 text-gray-700">
          <span className="font-semibold">เด็กไม่มีเตียง</span>
        </td>
        <td className="border px-4 py-2 text-gray-700 text-center">{childNoBed} ท่าน</td>
        <td className="border px-4 py-2 text-gray-700 text-right">
          {childNoBed > 0 ? (
            formatCurrency(
              ((Number(selectedPeriod.price_child_no_bed) || 0) - (Number(selectedPeriod.discount) || 0)) * childNoBed
            )
          ) : (
            '฿0'
          )}
        </td>
      </tr>
      {/* Total Row */}
      <tr className="bg-gray-100 font-semibold">
        <td className="border px-4 py-2 text-right" colSpan="2">ยอดรวม:</td>
        <td className="border px-4 py-2 text-right text-green-600">
          {formatCurrency(totalPrice)}
        </td>
      </tr>
    </tbody>
  </table>
</div>



</div>
</div>


              ) : null
            ) : (
              <p>กำลังโหลดข้อมูล...</p>
            )}
          </div>






          </div>
          <div className="flex justify-end items-center gap-x-2 py-3 px-4 mt-auto border-t ">


            <div className="flex justify-center items-center mt-2 flex-shrink-0">


  {step === 1 ? (
    <>
<div className="flex justify-between items-center mt-4">
    <p className="text-blue-500 text-xl font-bold mr-8">ยอดรวม: {formatCurrency(totalPrice)}</p>
    
    <div className="flex gap-2 ml-auto">
        <button
            type="button"
            className="btn bg-gray-200 text-black"
            onClick={toggleModal}
        >
            ปิด
        </button>
        
        <button
            type="button"
            className="btn bg-blue-600 text-white"
            onClick={handleNextStep}
            disabled={!hasInput()}
        >
            ถัดไป
        </button>
    </div>
</div>

    </>
  ) : step === 2 ? (
    <>
                <button
              type="button"
              className="btn mr-2"
              onClick={toggleModal}
            >
              ปิด
            </button>
      <button
        type="button"
        className="btn mr-2"
        onClick={handlePreviousStep}
      >
        ย้อนกลับ
      </button>
  <button
    type="button"
    className="btn bg-blue-600 text-white"
    onClick={() => {
      if (name && mobile) {
        handleNextStep();
      } else {
        alert("กรุณากรอกข้อมูลให้ครบถ้วน");
      }
    }}
    disabled={!isEmailValid}
    
  >
    ถัดไป
  </button>
    </>
  ) : step === 3 ? (
    <>
                <button
              type="button"
              className="btn mr-2"
              onClick={toggleModal}
            >
              ปิด
            </button>

      <button
        type="button"
        className="btn mr-2"
        onClick={handlePreviousStep}
      >
        ย้อนกลับ
      </button>
<button
  type="button"
  className={`btn bg-blue-600 text-white flex items-center justify-center ${isLoading ? 'cursor-not-allowed opacity-75' : ''}`}
  onClick={handleSubmit1}
  disabled={isLoading} // Disable the button while loading
>
  {isLoading ? (
<span className="loading loading-spinner text-white"></span>
  ) : (
    'ส่งข้อมูล'
  )}
</button>

    </>
  ) : null}
</div>

          </div>
        </div>
      </div>

      </form>{/*Form end */}
                  
                </div>
                </section>

                <section id="contact">
                <div className="px-6 pt-10 mb-4">
                <div className="font-bold mt-2 mb-2 text-2xl flex items-center text-blue-500">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-ticket-perforated mr-2" viewBox="0 0 16 16">
      <path d="M4 4.85v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9zm-7 1.8v.9h1v-.9zm7 0v.9h1v-.9z"/>
      <path d="M1.5 3A1.5 1.5 0 0 0 0 4.5V6a.5.5 0 0 0 .5.5 1.5 1.5 0 1 1 0 3 .5.5 0 0 0-.5.5v1.5A1.5 1.5 0 0 0 1.5 13h13a1.5 1.5 0 0 0 1.5-1.5V10a.5.5 0 0 0-.5-.5 1.5 1.5 0 0 1 0-3A.5.5 0 0 0 16 6V4.5A1.5 1.5 0 0 0 14.5 3zM1 4.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1.05a2.5 2.5 0 0 0 0 4.9v1.05a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1.05a2.5 2.5 0 0 0 0-4.9z"/>
    </svg>
    ติดต่อ แผนกทัวร์ต่างประเทศ
  </div>

                </div>
                </section>
{/* เงื่อนไขการจองทัวร์ */}

<div className="px-6 pt-10 mb-4">
<div className="font-bold mt-2 mb-2 text-xl sm:text-2xl flex items-center text-blue-500">

    เงื่อนไขการจอง และ ข้อตกลงการซื้อแพคเกจทัวร์กับ Thaifly.com
  </div>

  <div className="join join-vertical w-full">


    {/* Accordion Item 1 */}
    <section id="conditions">
    <div className="collapse collapse-arrow join-item border-base-300 border">
      <input type="checkbox" className="peer" defaultChecked />
      <div className="collapse-title text-xl font-medium">
        เงื่อนไขการจองทัวร์ รหัสทัวร์ {programDetail.model}
      </div>
      <div className="collapse-content">
        <div>* กรุณาสำรองที่นั่ง พร้อมชำระเงินมัดจำค่าทัวร์ ตามระบุในรายการทัวร์ ภายในระยะเวลาที่บริษัทกำหนด</div>
        <div>* กรุณาชำระค่าทัวร์ส่วนที่เหลือชำระก่อนเดินทาง 15 วันทำการ (ไม่นับรวมวันเสาร์ อาทิตย์ และวันหยุดราชการ)</div>
        <div>* กรณีเดินทางตามรายการทัวร์ที่ไม่มีวีซ่า (Non Visa) ส่วนที่เหลือชำระ ก่อนการเดินทางไม่น้อยกว่า 15 วันทำการ (ไม่นับรวมวันเสาร์ อาทิตย์ และวันหยุดราชการ) มิฉะนั้นจะถือว่าท่านยกเลิกการเดินทางโดยอัตโนมัติ</div>
        <div>* กรณีเดินทางตามรายการทัวร์ที่มีวีซ่า (Visa) กรณีวีซ่าไม่ผ่านการพิจารณาจากทางสถานฑูต บริษัทฯ ขอสงวนสิทธิ์ในการหักค่าธรรมเนียมการยื่นวีซ่า และ/หรือ ค่าใช้จ่ายตามจริง ส่วนที่เหลือชำระทันทีที่ผลพิจารณาวีซ่าออก หรือก่อนการเดินทางไม่น้อยกว่า 15 วันทำการ มิฉะนั้นจะถือว่าท่านยกเลิกการเดินทางโดยอัตโนมัติ</div>
        <div>* การจองทัวร์ / บริการใดๆ จะมีสถานะสมบูรณ์เมื่อทางบริษัทได้รับเอกสารที่ใช้ประกอบการจองทัวร์ครบ พร้อมเอกสารยืนยันการชำระเงินค่ามัดจำทัวร์ตามระบุในรายการทัวร์เท่านั้น มิฉะนั้น บริษัทขอสงวนสิทธิ์การยกเลิกการจองของท่านโดยอัตโนมัติ</div>
      </div>
    </div>
    </section>

    {/* Accordion Item 2 */}
    <section id="conditions2">
    <div className="collapse collapse-arrow join-item border-base-300 border">
      <input type="checkbox" className="peer" defaultChecked />
      <div className="collapse-title text-xl font-medium">
        ข้อตกลงการซื้อแพคเกจทัวร์กับ Thaifly.com | Other Conditions
      </div>
      <div className="collapse-content">
        <div className="mb-2">
          ข้อตกลงการซื้อแพคเกจทัวร์กับ Thaifly.com | Other Conditions
          <br />
          ทางบริษัท Thaifly Travel Co.,Ltd. ขอแจ้งข้อตกลงการซื้อบริการแพคเกจทัวร์กับลูกค้าทุกท่าน เพื่อให้การตกลงซื้อขายบริการใดๆ จากบริษัทฯ เป็นไปโดยสมบูรณ์ กรุณาดำเนินการดังต่อไปนี้:
        </div>
        <div>1. กรุณาตรวจสอบความถูกต้องของข้อมูลส่วนตัวของผู้ร่วมเดินทางทุกท่าน</div>
        <div>* ชื่อ + นามสกุล ของผู้โดยสารทุกคน</div>
        <div>* คำนำหน้า ชื่อ เช่น Mr. / Mrs. / Miss.</div>
        <div>* ชื่อ / ชั้นยศ / ทางตำรวจ / ทหารเป็นต้น (ถ้ามีและต้องการระบุ)</div>
        <div>* เบอร์สะสมไมล์ของสายการบินต่าง ๆ (ในกรณีที่สามารถสะสมไมล์ได้)</div>
        <div>2. กรณีมีเด็กร่วมเดินทาง โปรดตรวจสอบว่าอายุเด็กอยู่ในเกณฑ์ที่จะต้องใช้ราคาตั๋วเครื่องบินประเภทใดตรงตามอายุของเด็กหรือไม่</div>
        <div>3. สำคัญมาก: โปรดตรวจสอบอายุการใช้งานของหนังสือเดินทาง (Passport) ของผู้โดยสาร และผู้ร่วมเดินทางทุกท่าน ว่าจะต้องคงมีอายุเหลือ ณ วันเดินทาง มากกว่า 6 เดือน ขึ้นไป</div>
        <div>
          4. สำคัญมาก: ในกรณีที่ท่านจะใช้หนังสือเดินทางราชการ (เล่มสีน้ำเงิน) เดินทางกับคณะ บริษัทฯ สงวนสิทธ์ที่จะไม่รับผิดชอบ หากท่านถูกปฏิเสธการเข้าหรือออกนอกประเทศใดประเทศหนึ่ง เพราะโดยปกติเราใช้หนังสือเดินทางบุคคลธรรมดา เล่มสีเลือดหมู
        </div>
        <div>
          5. บริษัทฯ เป็นตัวแทนในการจัดนำสัมมนาและการเดินทางที่มีความชำนาญ โดยจัดหาโรงแรมที่พัก อาหาร ยานพาหนะ และสถานที่ท่องเที่ยวพร้อมทั้งการสัมมนา ดูงาน เพื่อความสะดวกสบาย และเกิดประโยชน์สูงสุดในการเดินทาง ทั้งนี้ทางบริษัทฯ
          ไม่สามารถรับผิดชอบในอุบัติเหตุหรือความเสียหายที่เกิดจากโรงแรมที่พัก ยานพาหนะ, อันเนื่องจากอุบัติเหตุรวมถึงภัยธรรมชาติ, โจรกรรม, วินาศกรรม, อัคคีภัย, การผละงาน, การจลาจล สงครามการเมือง, การผันผวนของอัตราแลกเปลี่ยนเงินตราระหว่างประเทศ,
          การนัดหยุดงาน, ความล่าช้าของเที่ยวบิน, สายการเดินเรือ, รถไฟ, พาหนะท้องถิ่น, ตลอดจนการถูกปฏิเสธออกวีซ่าจากกงสุล และ/หรือ ส่วนงานที่เกี่ยวข้องกับสถานเอกอัครราชฑูต รวมถึงผู้มีอำนาจทำการแทนประจำประเทศไทย
          (โดยไม่จำต้องแสดงเหตุผล เนื่องจากเป็นสิทธิพิเศษทางการทูต) ซึ่งอยู่เหนือการควบคุมของบริษัทฯ
        </div>
        <div className="font-bold text-l mt-2">
          เงื่อนไขการยกเลิกและเงื่อนไขการเปลี่ยนแปลง | Cancellation Conditions
          <br />
          * บริษัทขอสงวนสิทธิ์ การยกเลิกทุกกรณี การเปลี่ยนแปลง และเงื่อนไขการคืนเงินใดๆ ให้เป็นไปตามระบุในรายการทัวร์ที่ลูกค้าจองเท่านั้น
        </div>
        <div className="font-bold text-sm mt-4">
          หมายเหตุทัวร์ | Remark
        </div>
        <div>
          * บริษัทฯ รับเฉพาะผู้มีวัตถุประสงค์เดินทางเพื่อการท่องเที่ยวเท่านั้น
          <br />
          * กรณีที่เกิดเหตุจำเป็นสุดวิสัยจากการล่าช้า (Delay) ของทางสายการบิน ทำให้ไม่สามารถกิน-เที่ยวและพักโรงแรมได้ครบถ้วนตามโปรแกรมทัวร์ที่ระบุ ทางบริษัทฯ ขอสงวนสิทธิ์ในการที่จะไม่คืนค่าใช้จ่ายใด ๆ ทั้งสิ้น เพราะถือเป็นเหตุสุดวิสัยที่เกิดขึ้นโดยตรงจากทางสายการบิน และทางบริษัทฯ ไม่ได้รับค่าชดเชยใด ๆ ทั้งสิ้นจากทางสายการบิน
          <br />
          * บริษัทฯ ขอสงวนสิทธ์ในการที่จะเปลี่ยนแปลงการเดินทาง หรือยกเลิก หรือเปลี่ยนแปลงอัตราค่าบริการได้ตามความเหมาะสม โดยไม่ต้องแจ้งล่วงหน้า และสงวนสิทธิ์ที่จะเปลี่ยนแปลงรายการท่องเที่ยวในกรณีที่มีเหตุจำเป็นสุดวิสัย โดยจะพยายามให้เกิดประโยชน์สูงสุดกับท่าน
          <br />
          * หากระหว่างการเดินทาง ท่านถูกเจ้าหน้าที่ตรวจคนเข้าเมือง (Immigration) ของประเทศไทย หรือประเทศปลายทาง ปฏิเสธการเข้า หรือ ออกเมือง ด้วยเหตุผลใด ๆ ก็ตาม ทำให้ท่านไม่สามารถเดินทางต่อไปได้ ซึ่งถือเป็นเหตุซึ่งอยู่นอกเหนืออำนาจและความรับผิดชอบของบริษัทฯ ทางบริษัทฯ ขอสงวนสิทธิ์ที่จะไม่คืนเงินค่าทัวร์ทั้งหมด
          <br />
          * บริษัทฯ ไม่รับผิดชอบต่อค่าใช้จ่ายใด ๆ ทั้งสิ้น ในกรณีที่เกิดเหตุสุดวิสัยต่าง ๆ เช่น การยกเลิกเที่ยวบิน, การล่าช้าของสายการบิน, อุบัติเหตุ, ภัยธรรมชาติ, การจลาจล, การนัดหยุดงาน, และทรัพย์สินส่วนตัวสูญหาย หรือหลงลืมตามสถานที่ต่าง ๆ ที่เกิดขึ้น ซึ่งเหตุการณ์ดังกล่าวอยู่นอกเหนืออำนาจการควบคุมของบริษัทฯ
          <br />
          * ในระหว่างการเดินทางท่องเที่ยวนี้ หากท่านไม่ใช้บริการใด ๆ ไม่ว่าทั้งหมดหรือบางส่วน ให้ถือว่าท่านได้สละสิทธิ์ และไม่สามารถเรียกร้องขอเงินคืนค่าบริการได้
          <br />
          * บริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงราคา และเงื่อนไขต่าง ๆ โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
        </div>
        <div className="text-l mt-4 mb-2 font-bold"> เงื่อนไขแพคเกจ {programDetail.name}</div>
        <div>
          บริษัท ไทยฟลาย ทราเวล จำกัด จะไม่รับผิดชอบใดๆ และสงวนสิทธิ์การคืนเงินทุกกรณีหากท่านฝ่าฝืนกระทำการแก้ไข คัดลอก ตัดต่อ ดัดแปลง เพิ่มหรือลด ข้อความใดๆ ภายในเอกสารรายการเที่ยว/โปรแกรมทัวร์ทั้งหมด ทั้งในเว็บไซต์และเอกสารฉบับจริงโดยไม่ได้รับการอนุญาตจากบริษัทฯ โดยเด็ดขาด บริษัทฯ ขอสงวนสิทธิ์ในการยกเลิกการเดินทาง หากมีจำนวนผู้เดินทางไม่ถึงขั้นต่ำตามที่โปรแกรมระบุไว้ ซึ่งบริษัทฯ จะรับผิดชอบโดยการคืนเงินเต็มจำนวนตามที่ลูกค้าได้ชำระมาแล้ว
        </div>
      </div>
    </div>
    </section>

    {/* Accordion Item 3 */}
    <section id="conditions3">
    <div className="collapse collapse-arrow join-item border-base-300 border">
      <input type="checkbox" className="peer" defaultChecked />
      <div className="collapse-title text-xl font-medium">
        เอกสารที่ต้องเตรียมไป
      </div>
      <div className="collapse-content">
        <div>1. สำคัญมาก: โปรดตรวจสอบอายุการใช้งานของหนังสือเดินทาง (Passport) ของผู้โดยสาร และผู้ร่วมเดินทางทุกท่าน ก่อนส่งมาเพื่อยืนยันการทำจอง ว่าหมดอายุหรือไม่ เนื่องจากพาสปอร์ตที่ใช้ในการเดินทางได้ต้องมีอายุเหลืออยู่อย่างน้อย 6 เดือนขึ้นไป นับจากวันเดินทาง ไป-กลับของรายการทัวร์ที่ลูกค้าจอง</div>
        <div>2. สำเนา/ตัวจริง หนังสือเดินทางไทย (Thai's Passport) ที่มีอายุการใช้งานเกิน 6 เดือน นับจากวันที่จะเดินทางล่าสุด / มีสภาพดีไม่ชำรุด</div>
        <div>3. สำเนา/ตัวจริง หนังสือเดินทางต่างด้าว (Foreigner's Passport) ที่มีอายุการใช้งานเกิน 6 เดือน นับจากวันที่จะเดินทางล่าสุด / มีสภาพดีไม่ชำรุด (บริษัทขอสงวนสิทธิ์การเรียกเก็บค่าใช้จ่ายเพิ่มเติมใดๆ ตามระบุในรายการทัวร์ ถ้ามี)</div>
        <div>4. เอกสารยืนยันการชำระเงินค่ามัดจำทัวร์ ตามระบุในรายการทัวร์</div>
      </div>
    </div>
    </section>

  </div>
</div>




            </div>


            <div className="hidden lg:block">
<Footer />
</div>

        </>
    );
};

export default Detail; 
import React, { useState, useEffect, useRef, useContext } from 'react';
import { auth, db, googleProvider, facebookProvider } from '../components/firebase/firebase'; // ปรับเส้นทางตามที่จำเป็น
import { onAuthStateChanged } from 'firebase/auth';
import { onValue, ref } from 'firebase/database';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import Register from '../components/firebase/Register';
import Login from '../components/firebase/Login';
import { UserContext } from '../contexts/UserContext';

const Navbar = () => {

  return (
        <>
<div className="navbar bg-white backdrop-blur-md fixed top-0 left-0 right-0  z-50 ">
    <div className="navbar-start">
        <div className="dropdown">
            <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
                </svg>
            </div>

        </div>
        <div className="btn btn-ghost text-xl">
        <ul className="menu menu-horizontal px-1">
        <li className="p-1">  <div className="skeleton h-10 w-32"></div></li>
        </ul>

        </div>
    </div>

    {/* desktop view */}
    <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">

            <li className="p-1">  <div className="skeleton h-4 w-full"></div></li>
            <li className="p-1">  <div className="skeleton h-4 w-full"></div></li>
            <li className="p-1">  <div className="skeleton h-4 w-full"></div></li>
            <li className="p-1">  <div className="skeleton h-4 w-full"></div></li>
            <li className="p-1">  <div className="skeleton h-4 w-full"></div></li>
            <li className="p-1">  <div className="skeleton h-4 w-full"></div></li>

        </ul>
    </div>


    <div className="navbar-end">





    <div className="dropdown dropdown-end">
    <ul className="menu menu-horizontal px-1">
    <li className="p-1">  <div className="skeleton h-4 w-full"></div></li>
    </ul>
    
  </div>


             {/* Button to open modal */}
             <div>
      {/* Button to open modal */}
{/* Button to open modal */}
<div className="dropdown dropdown-end">


    <div className="skeleton h-12 w-12 shrink-0 rounded-full"></div>
    </div>



      {/* การ์ดสำหรับ Sign in with Google */}

    </div>








    </div>

</div>



        </>
    );
};

export default Navbar;
